import { AccordionDetails, Grid, Box, Button } from '@material-ui/core';

import { Form, useForm } from 'frmx';

import AccordionForm from 'components/forms/AccordionForm';
import Job from 'entities/Job/Job';
import { useIsBelowSm } from 'hooks/useMQ';
import { useAuth } from 'hooks/useAuth';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { setCompanyInfo } from 'store/authSlice';
import useNotifications from 'hooks/useNotifications';
import { useTranslation } from 'react-i18next';

export default function JobsSettings({ section }) {
  const isBelowSm = useIsBelowSm();
  const auth = useAuth();

  const { dispatch } = useAsyncDispatch();

  const notify = useNotifications();

  const clientType = auth.interface.isClient ? 'client' : 'contractor';

  return (
    <AccordionForm
      section={section}
      initialValues={{
        _jobs: auth.interface?._company?._jobs || []
      }}
      allwaysExpanded
    >
      <AccordionDetails style={{ margin: 0, padding: 0, height: isBelowSm ? '50vh' : '70vh' }}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Form
              style={{ height: '100%' }}
              initialValues={{ _jobs: auth.interface._company._jobs }}
              onSubmit={(data) =>
                dispatch(
                  setCompanyInfo,
                  { [clientType]: { _jobs: data._jobs.map((j) => ({ _id: j._id })) } },
                  { onSuccess: () => notify.success() }
                )
              }
            >
              <JobsForm />
            </Form>
          </Grid>
        </Grid>
      </AccordionDetails>
    </AccordionForm>
  );
}

function JobsForm() {
  const { handleSubmit } = useForm();
  const { t } = useTranslation();

  return (
    <Box
      height="100%"
      width="100%"
      display="flex"
      flexDirection="column"
    >
      <div className="h-full w-full flex flex-col relative">
        <Job
          pickerCreate
          pickerField="_jobs"
          picker
          hiddenFilters={{ allJob: true }}
          settings
        />
      </div>
      <Box
        display="flex"
        justifyContent="flex-end"
        padding="4px 6px"
        bgcolor="white"
        borderRadius="0px 0px 8px 8px"
      >
        <Button
          onClick={(e) => [handleSubmit(e)]}
          color="primary"
          size="large"
        >
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
}
