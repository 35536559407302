import useKeys from '@flowsn4ke/usekeys';
import { ChatBubbleLeftEllipsisIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { Delete, Edit } from '@material-ui/icons';
import axios from 'axios';
import { Field, Form, Submit } from 'frmx';
import { useAuth } from 'hooks/useAuth';
import useNotifications from 'hooks/useNotifications';
import { apiBaseURL } from 'index';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateToLocalFormat } from 'utils/dates';

export default function Feed({ readOnly, comments_tab_id }) {
  const [comments, setComments] = useState([]);
  const k = useKeys();
  const { t } = useTranslation();
  const notify = useNotifications();

  useEffect(() => {
    comments_tab_id &&
      axios
        .get(`${apiBaseURL}/comments/${comments_tab_id}`)
        .then(({ data }) => setComments(data.comments))
        .catch((err) => notify.error(t('coulNotLoadComments')));
  }, [comments_tab_id]);

  const initialValues = useMemo(() => {
    return { message: '' };
  }, []);

  async function onSubmit(data) {
    const { message } = data;
    // TODO: Feed id should be in the url
    axios
      .post(`${apiBaseURL}/comments/${comments_tab_id}`, { message })
      .then((res) => {
        setComments([res.data.comment, ...comments]);
        notify.success(t('commentSuccessMessage'));
      })
      .catch((err) => {
        notify.error(t('errorOccured'));
      });
  }

  return (
    <div className="mt-2 flex flex-col">
      <div className="flex flex-col mt-4 mb-6 space-y-4 divide-y">
        {!readOnly ? (
          <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            clearAfterSubmit
          >
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="comment"
              >
                {t('newComment')}
              </label>
              <Field
                path="message"
                type="textarea"
              >
                <textarea
                  rows={4}
                  className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="comment"
                  placeholder={t('addNewComment')}
                />
              </Field>
              <div className="flex justify-end my-1 ">
                <Submit>
                  <button className="inline-flex justify-center rounded-md border border-transparent bg-green-100 px-2 py-1 text-xs font-medium text-green-900 hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2">
                    {t('add')}
                  </button>
                </Submit>
              </div>
            </div>
          </Form>
        ) : null}
        <div className="">
          <ul className="mt-4">
            {comments
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((comment, commentsIdx) => {
                return (
                  <Comment
                    key={k(commentsIdx)}
                    comments={comments}
                    commentsIdx={commentsIdx}
                    setComments={setComments}
                    avatar={comment.created_by.avatar}
                    commentId={comment._id}
                    message={comment.message}
                    updated_at={comment.updated_at}
                    created_by={comment.created_by}
                    created_at={comment.created_at}
                    readOnly={readOnly}
                  />
                );
              })}
          </ul>
        </div>
      </div>
    </div>
  );
}

function Comment({
  comments,
  commentsIdx,
  setComments,
  avatar,
  commentId,
  message,
  updated_at,
  created_by,
  created_at,
  readOnly
}) {
  const { t } = useTranslation();
  const notify = useNotifications();
  const auth = useAuth();

  const [editInput, setEditInput] = useState({});

  const handleClickEditing = (id) => {
    setEditInput({
      isEdit: true,
      commentId: id
    });

    if (editInput?.isEdit) {
      setEditInput({
        isEdit: false,
        commentId: null
      });
    }
  };

  const handleClickDelete = (id) => {
    axios
      .delete(`${apiBaseURL}/comments/${id}`)
      .then((res) => {
        setComments(comments.filter((comment) => comment._id !== id));
        notify.success(t('deletedCommentSuccessMessage'));
      })
      .catch((err) => {
        notify.error();
      });
  };

  const handleSubmitChangeComment = (data) => {
    const { commentId, message } = data;
    axios
      .put(`${apiBaseURL}/comments/${commentId}`, { message })
      .then(({ data }) => {
        setComments(
          comments.map((comment) => (comment._id === commentId ? data.comment.value : comment))
        );
        notify.success(t('updatedCommentSuccessMessage'));
        handleClickEditing(commentId);
      })
      .catch((err) => {
        notify.error();
      });
  };

  const canEditActions = auth.user._id === created_by._id && !readOnly;

  return (
    <li>
      <div className="relative pb-8">
        {commentsIdx !== comments.length - 1 ? (
          <span
            className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />
        ) : null}
        <div className="relative flex items-start space-x-3">
          <div className="relative">
            {avatar ? (
              <img
                className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white"
                src={avatar}
                alt=""
              />
            ) : (
              <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                <UserCircleIcon
                  className="h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              </div>
            )}
            <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
              <ChatBubbleLeftEllipsisIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </div>
          <div className="group min-w-0 flex-1">
            <div className="">
              <div className="text-sm flex justify-between">
                <p className="font-medium text-gray-900">{created_by.name}</p>
                <div
                  className={`${canEditActions ? ' invisible mr-1 group-hover:visible' : 'hidden'}`}
                >
                  <div className="text-gray-500">
                    <button
                      className="mr-1"
                      onClick={() => handleClickEditing(commentId)}
                    >
                      <Edit />
                    </button>
                    <button onClick={() => handleClickDelete(commentId)}>
                      <Delete />
                    </button>
                  </div>
                </div>
              </div>
              <p className="mt-0.5 text-sm text-gray-500">
                {t('addThe')} {dateToLocalFormat(created_at, 'Pp')}
              </p>
            </div>
            <div className="mt-2 text-sm text-gray-700">
              {editInput.isEdit && editInput.commentId === commentId ? (
                <>
                  <Form
                    initialValues={{ message: message || '', commentId: editInput?.commentId }}
                    onSubmit={(data) => handleSubmitChangeComment(data)}
                    className="flex flex-col items-end pr-2"
                  >
                    <Field
                      path="message"
                      type="text"
                    >
                      <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
                    </Field>
                    <Submit>
                      <button className="ml-4 text-grey-500 bg-sky-100 hover:bg-sky-200 font-semibold text-sky-700 p-2 rounded mt-2">
                        {t('edit')}
                      </button>
                    </Submit>
                  </Form>
                </>
              ) : (
                <p className="whitespace-pre-wrap inline-flex">
                  {message}
                  {updated_at ? <span className="text-gray-500 ml-1">({t('edited')})</span> : null}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
