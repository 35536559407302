import { isEmail } from 'validator';
import { pick } from 'lodash-es';

const TechnicianFormValues = (configuration) => ({
  firstName: '',
  lastName: '',
  zones: [],
  zone_ids: [],
  _jobs: [],
  _role: null,
  email: '',
  phone: '',
  fix: '',
  userInterface: true,
  sendEmail: true,
  lang: ''
});
export default TechnicianFormValues;

export const formatForm = (dataFromForm, isCreate, attachments) => ({
  ...dataFromForm,
  intervenerType: 'technician',
  avatar: !!attachments?.length ? attachments[0].url : '',
  _user: dataFromForm.userInterface ? pick(dataFromForm, ['firstName', 'lastName', 'avatar']) : null
});

export const TechnicianValidation = (config, data) => ({
  _role: (value, data) => (data.userInterface ? !!value : true),
  email: (value, data) => (!!(data.userInterface || value?.length) ? isEmail(value) : true),
  userInterface: (value, data) => (!!(value || data.email?.length) ? isEmail(data.email) : true)
});

export const injectFormValues = (initialValues, technician, auth) => {
  return initialValues;
};
