import { Button, Typography } from '@material-ui/core';
// import { LoginForm } from './LoginForm';
// import { LoginGoogle } from './LoginGoogle';
import FAIcon from 'components/ui/FAIcon';
import classNames from 'classnames';
import useStyles from './Portal.styles';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SystemsStatus from 'components/SystemsStatus';
import { OldLoginForm } from './OldLoginForm';

export const LoginPage = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col">
        <Link to="/sso-login">
          <Button
            className={classNames(classes.spacing, classes.submitButtonContainer)}
            variant="outlined"
            color="secondary"
            disableElevation
          >
            <Typography>{t('continue-with-SSO')}</Typography>
            <FAIcon
              icon="lock"
              collection={'fas'}
              size="medium"
            />
          </Button>
        </Link>

        {/* <LoginGoogle /> */}
      </div>
      <div className="flex items-center my-4">
        <div className="border-t border-gray-300 flex-grow"></div>
        <div className="mx-4 text-gray-500">{t('or')}</div>
        <div className="border-t border-gray-300 flex-grow"></div>
      </div>
      <OldLoginForm />
      {/* <LoginForm /> */}
      <SystemsStatus />
    </>
  );
};
