import Client from 'entities/Client/Client';
import Scope from 'entities/Scope/Scope';
import Job from 'entities/Job/Job';
import Location from 'entities/Location/Location';
import { equipmentWear } from 'lists/equipmentWear';
import { operational_states_equipment } from 'lists/operational_states_equipment';
import { status_of_equipment } from 'lists/status_of_equipment';

const EquipmentFilters = (config, { customFields, lockClients, trash }, auth) => {
  return [
    {
      label: 'client',
      key: 'clients',
      type: 'Entity',
      params: {
        entity: Client,
        entityProps: {
          authorisations: [],
          regime: [],
          section: {}
        }
      },
      show: config.isContractor || config.isMaster,
      disabled: false,
      default: [],
      lock: lockClients
    },
    {
      label: config.isBlachere ? 'blachCategories' : 'categories',
      key: 'categories',
      type: 'Entity',
      params: {
        entity: Scope,
        entityProps: {
          type: 'equipmentCategory',
          hiddenFilters: {
            only: [],
            parents: []
          }
        }
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'subCategory',
      key: 'subcategories',
      type: 'Entity',
      params: {
        entity: Scope,
        entityProps: {
          type: 'equipmentSubCategory',
          hiddenFilters: {
            only: [],
            parents: []
          }
        }
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'dilapidationState',
      key: 'states',
      type: 'List',
      params: {
        datas: equipmentWear
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'operationalState',
      key: 'operational_states',
      type: 'List',
      params: {
        datas: operational_states_equipment
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'statusOfEquipment',
      key: 'status',
      type: 'List',
      params: {
        datas: status_of_equipment
      },
      show: true,
      disabled: false,
      default: trash ? [] : ['ACTIVE', 'ON_HOLD', 'PLAN_REPLACEMENT']
    },
    {
      label: 'location',
      key: 'locations',
      type: 'Entity',
      params: {
        entity: Location,
        entityProps: {
          type: 'locations',
          hiddenFilters: {
            section: {
              location: true,
              facturation: false
            }
          }
        }
      },
      show: true,
      disabled: false,
      default: [],
      hidden: !!auth.interface?._locations?.length,
      lock: !!auth.interface?._locations?.length
    },
    {
      label: 'job',
      key: 'jobs',
      type: 'Entity',
      params: {
        entity: Job,
        entityProps: {
          type: 'jobs',
          hiddenFilters: {
            section: {
              mine: true,
              bobdesk: true,
              bobdepannage: true
            }
          }
        }
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'field',
      key: 'fields',
      type: 'Field',
      params: {},
      show: customFields?.some((section) => !!section.fields?.length),
      disabled: false,
      default: []
    }
  ].filter((e) => e.show);
};
export const formatFilter = (filters, auth) => {
  const locationsUserInterfaceDefined = auth.interface?._locations;
  const userHasLocationsDefined = locationsUserInterfaceDefined?.length > 0;

  if (!filters.locations?.length && userHasLocationsDefined) {
    filters['locations'] = locationsUserInterfaceDefined;
  }

  return filters;
};

export default EquipmentFilters;
