import Manager from 'entities/Manager/Manager';
import { Form, Submit, useField } from 'frmx';
import { updateElementManagers } from 'store/clientsSlice';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { Fab } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import BlurredProgress from 'components/BlurredProgress';
import { useTranslation } from 'react-i18next';

export default function Managers({ element }) {
  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();
  const { t } = useTranslation();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(t('savedChanges')),
    onError: () => notify.error()
  };

  return (
    <Form
      initialValues={{
        _managers: element.partner?._managers?.filter((v) => !!v) || []
      }}
      onSubmit={(data) => {
        const next = data._managers.map((m) => (m?._id ? m._id : m));
        dispatch(updateElementManagers, { managers: next }, dispatchCallbacks, { id: element._id });
      }}
      style={{ height: '100%' }}
    >
      <ManagersFormContent disabled={requestStatus === 'loading'} />
    </Form>
  );
}

function ManagersFormContent(props) {
  const { t } = useTranslation();
  const { disabled } = props;
  const fldx = useField('_managers');

  return (
    <>
      <BlurredProgress in={disabled} />
      <Manager
        fldx={fldx}
        disabled={disabled}
        picker
        pickerField="_managers"
      />
      <Submit>
        <Fab
          style={{
            margin: 0,
            position: 'absolute',
            right: 24,
            bottom: 16,
            zIndex: 999
          }}
          variant="extended"
          color="primary"
        >
          {t('save')}
          <FAIcon
            collection="fal"
            icon="floppy-disk"
            style={{ marginLeft: '1em' }}
          />
        </Fab>
      </Submit>
    </>
  );
}
