import { Fab } from '@material-ui/core';
import { Print } from '@material-ui/icons';

export default function QRCode({ element, type, line1 = '', line2 = '' }) {
  console.log(element);
  const url = `${process.env.REACT_APP_API_URL}/qrcode?type=${type}&id=${element._id}&line1=${line1}&line2=${line2}`;

  const printImage = () => {
    var win = window.open(url);
    win.document.write('<img style="width:10em;" src="' + url + '" onload="window.print();window.close();" />');
    win.focus();
  };

  return (
    <div className="flex h-full items-center justify-center bg-white">
      <img
        style={{ maxWidth: '20rem' }}
        src={url}
        alt="QRCode"
        className="drop-shadow-lg rounded-md"
      />
      <Fab
        style={{ position: 'absolute', bottom: 16, right: 16 }}
        color="secondary"
        size="small"
        onClick={printImage}
      >
        <Print />
      </Fab>
    </div>
  );
}
