import ReportFile from 'entities/ReportFile/ReportFile';

export default function ReportFilePage() {
  return (
    <div className="p-2">
      <div className="bg-white rounded">
        <ReportFile />
      </div>
    </div>
  );
}
