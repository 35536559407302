import { useAuth } from 'hooks/useAuth';
import Documents from 'modules/documents';

export default function DocumentPage() {
  const auth = useAuth();

  return (
    <Documents
      tab_id={null}
      company_id={auth.interface._company._id}
      with_filters={true}
    />
  );
}
