import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  IconButton,
  Tooltip,
  Paper,
  Button,
  Collapse,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  InputBase,
  Typography
} from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import clsx from 'clsx';

import { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from 'hooks/useAuth';
import { logout, logoutUser, switchInterface, switch_interface } from 'store/authSlice';

import { INTERFACE_MASTER } from 'constants/roles';
import PopoverDrawer from 'components/ui/PopoverDrawer';
import Avatar from 'components/Avatar';

import useStyles from './InterfacePopover.styles';
import { useTranslation } from 'react-i18next';
import useNotifications from 'hooks/useNotifications';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import { attachClient } from 'store/authSlice';
import { useHistory } from 'react-router-dom';
import useKeys from '@flowsn4ke/usekeys';
import { useIsBelowSm } from 'hooks/useMQ';

import SettingsDrawer from 'layouts/settings/SettingsDrawer';
import classNames from 'utils/classNames';
import { supabase } from '../../utils/supabaseClient';

export function ProfileCard({ userInfos }) {
  const auth = useAuth();
  const user = userInfos || auth.user;

  const classes = useStyles({ isFirstConnect: !!userInfos });

  return (
    <Box className={classes.profileCard}>
      <Box className={classes.profileInfo}>
        <Typography
          variant="h4"
          className={classes.h3}
        >
          {auth.interface._company.name}
        </Typography>
        <Typography
          variant="h5"
          className={classes.h5}
        >
          {user.firstName + ' ' + user.lastName?.toUpperCase()}
        </Typography>
        <Typography
          variant="h6"
          className={classes.h6}
        >
          {auth.interface._role?.name}
        </Typography>
      </Box>
      <Box className={classes.avatarBox}>
        <Avatar
          button
          white
          size="large"
          entity={auth.interface._company}
        />
        <Box className={classes.userAvatar}>
          <Avatar
            isUser
            button
            round
            size="small"
            entity={auth.user}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default function InterfacePopover({ id, anchorEl, open, onOpen, onClose }) {
  const syncDispatch = useDispatch();
  const auth = useAuth();
  const history = useHistory();

  const notify = useNotifications();
  const { dispatch } = useAsyncDispatch();

  const dispatchAttach = (attach, company) => {
    const dispatchCallbacks = {
      onSuccess: () => notify.success(`Client ${attach ? 'rattaché' : 'détaché'}!`),
      onError: () => notify.error()
    };
    dispatch(
      attachClient,
      {
        attach,
        parentType: company.isClient ? '_client' : '_contractor',
        type: company.isClient ? 'client' : 'contractor'
      },
      dispatchCallbacks,
      { id: company._id }
    );
  };

  const [textFilter, setTextFilter] = useState('');
  const [displayList, setDisplayList] = useState(false);

  const visibleResults = useMemo(() => {
    const interfaces = auth.user.interfaces;
    if (textFilter?.length === 0) {
      return interfaces.filter((i) => {
        return i._company.name !== 'Bob! Desk';
      });
    } else {
      return interfaces.filter((i) => {
        return (
          i._company.name !== 'Bob! Desk' &&
          i._company.name?.toLowerCase().includes(textFilter?.toLowerCase())
        );
      });
    }
  }, [auth.user.interfaces, textFilter]);

  const k = useKeys();
  const classes = useStyles();
  const { t } = useTranslation();

  const [isOpenSettings, setIsOpenSettings] = useState(false);

  const change_interface = async (_interface) => {
    syncDispatch(switchInterface(_interface));
    await dispatch(switch_interface, undefined, undefined, { id: _interface._id });
    history.push('/');
    window.location.reload();
  };

  const logout_user = async () => {
    syncDispatch(logout());

    // logout supabase
    await supabase.auth.signOut();

    await dispatch(logoutUser);
  };

  const masterInterface = useMemo(
    () => auth.user.interfaces.find((i) => i.type === 'master'),
    [auth.user.interfaces]
  );

  const isBelowSm = useIsBelowSm();

  return (
    <PopoverDrawer
      keepMounted
      id={id}
      transitionDuration={0}
      offset
      anchorEl={anchorEl}
      open={open}
      onOpen={onOpen}
      onClose={() => [setDisplayList(false), onClose()]}
      PaperProps={{ className: classes.paperInterface }}
      style={{ width: isBelowSm ? '100%' : '320px' }}
    >
      <Paper className={classes.root}>
        <ProfileCard />
        <Box className={classes.actions}>
          <Button
            endIcon={
              <FAIcon
                collection="fas"
                icon={'cog'}
                size="small"
              />
            }
            variant="contained"
            color="secondary"
            className={clsx(classes.actionsSettings, classes.actionButton, classes.fullWidth)}
            onClick={() => {
              setIsOpenSettings(true);
              onClose();
            }}
          >
            {t('interfacePopoverSettingsButton')}
          </Button>
          {masterInterface && (
            <IconButton
              className={classNames(classes.actionButton, classes.actionsList)}
              onClick={() => change_interface(masterInterface)}
            >
              <FAIcon
                className={classes.listButton}
                collection="fas"
                style={{ transform: 'scale(1.3)' }}
                icon="user-unlock"
                size="small"
              />
            </IconButton>
          )}
          {auth.user.interfaces?.length > 1 && (
            <Tooltip
              title={t('openAnotherSession')}
              placement={'left'}
            >
              <IconButton
                className={classNames(classes.actionButton, classes.actionsList)}
                onClick={() => setDisplayList(!displayList)}
              >
                <FAIcon
                  className={classes.listButton}
                  collection="fas"
                  icon="key"
                  size="small"
                />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip
            title={t('interfacePopoverLogOutButton')}
            placement="left"
          >
            <IconButton
              className={classNames(classes.actionButton, classes.actionsLogout)}
              onClick={logout_user}
            >
              <FAIcon
                className={classes.logoutButton}
                collection="fas"
                icon="power-off"
                size="small"
              />
            </IconButton>
          </Tooltip>

          <SettingsDrawer
            isOpen={isOpenSettings}
            onClose={() => setIsOpenSettings(false)}
          />
        </Box>

        {
          <Collapse in={displayList}>
            {displayList && (
              <Box className={classes.interfacesListContainer}>
                <Box className={classes.search}>
                  <InputBase
                    autoFocus
                    placeholder={t('searchEtc')}
                    className={classes.fullWidth}
                    value={textFilter}
                    onChange={(e) => setTextFilter(e.target.value)}
                  />
                </Box>
                <Box className={classes.interfacesList}>
                  <Grid
                    item
                    xs={12}
                  >
                    <div>
                      <List>
                        {visibleResults
                          .filter(
                            (interface_logged_in) =>
                              String(interface_logged_in._id) !== String(auth.interface._id)
                          )
                          .map((_interface, i) => (
                            <ListItem
                              button
                              key={k(i)}
                              className={classes.listItem}
                              onClick={() => {
                                change_interface(_interface);
                              }}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  entity={_interface._company}
                                  size="small"
                                  round
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={_interface._company.name}
                                secondary={_interface._role?.name}
                              />
                              <ListItemSecondaryAction>
                                {auth.interface.type === INTERFACE_MASTER && (
                                  <IconButton
                                    className={classes.unlinkAction}
                                    edge="end"
                                    aria-label="unlink account"
                                    onClick={() => dispatchAttach(false, _interface._company)}
                                  >
                                    <FAIcon
                                      collection="fad"
                                      size={'small'}
                                      icon="minus"
                                    />
                                  </IconButton>
                                )}
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                      </List>
                    </div>
                  </Grid>
                </Box>
              </Box>
            )}
          </Collapse>
        }
      </Paper>
    </PopoverDrawer>
  );
}
