import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import RouteWrapper from 'router/RouteWrapper';

//Layout
import Dashboard from 'layouts/dashboard/Dashboard';
import AuthWrapper from 'layouts/portal/Portal';

// Portal pages
// import ResetPasswordPage from 'pages/portal/ResetPasswordPage';
import OldResetPasswordPage from 'pages/portal/OldResetPasswordPage';
import SetPasswordPage from 'pages/portal/SetPasswordPage';
import FullScreenError from 'pages/FullScreenError';
import PublicPage from 'pages/PublicPage';

// Dashboard pages
import LocationPage from 'pages/dashboard/LocationPage';
import TicketPage from 'pages/dashboard/TicketPage';
import ClientsPage from 'pages/dashboard/ClientsPage';
import DeskPage from 'pages/dashboard/DeskPage';
import IntervenerPage from 'pages/dashboard/IntervenerPage';
import MaintenancePage from 'pages/dashboard/MaintenancePage';
import EquipmentPage from 'pages/dashboard/EquipmentPage';
import CustomerPage from 'pages/dashboard/CustomerPage';
import UserPage from 'pages/dashboard/UserPage';
import ManagerPage from 'pages/dashboard/ManagerPage';
import CalendarPage from 'pages/dashboard/CalendarPage';
import StockPage from 'pages/dashboard/StockPage';
import ApiPage from 'pages/dashboard/ApiPage';
import ContractPropositionPage from 'pages/dashboard/ContractPropositionPage';
import InterfaceMaintenancesUsersPage from 'pages/dashboard/InterfaceMaintenancesUsersPage';
import SafetyPage from 'pages/dashboard/SafetyPage';
import AccountingPage from 'pages/dashboard/AccountingPage';
import GEDPage from 'pages/dashboard/GEDPage';
import CalendarPage2 from 'pages/dashboard/CalendarPage2';
import DocumentPage from 'pages/dashboard/DocumentPage';
import SignUpPage from 'pages/portal/SignUpPage';
import MeterPage from 'pages/dashboard/MeterPage';
import TestPage from 'pages/dashboard/TestPage';
import QRCodeTarget from 'pages/QRCodeTarget';
import { LoginSSOPage } from 'pages/portal/LoginSSOPage';
import { LoginPage } from 'pages/portal/LoginPage';

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Switch>
        <RouteWrapper
          exact
          path="/"
          title={'login'}
          backTo="https://bob-desk.fr/"
          redirectIfLogged
          layout={AuthWrapper}
          component={LoginPage}
        />
        <RouteWrapper
          exact
          path="/sso-login"
          title={'login-sso'}
          backTo="/"
          redirectIfLogged
          layout={AuthWrapper}
          component={LoginSSOPage}
        />
        <RouteWrapper
          exact
          path="/sign-up"
          title={'signUpPageTitle'}
          backTo="https://bob-desk.fr/"
          redirectIfLogged
          layout={AuthWrapper}
          component={SignUpPage}
        />
        <RouteWrapper
          exact
          path="/reset-password"
          title={'resetPasswordPageTitle'}
          backTo="/"
          redirectIfLogged
          layout={AuthWrapper}
          component={OldResetPasswordPage}
        />
        <RouteWrapper
          exact
          path="/welcome/:token"
          title={'welcomePageTitle'}
          redirectIfLogged
          backTo="/"
          layout={AuthWrapper}
          component={SetPasswordPage}
        />
        <RouteWrapper
          exact
          path="/set-password/:token"
          title={'setPasswordPageTitle'}
          redirectIfLogged
          backTo="/"
          layout={AuthWrapper}
          component={SetPasswordPage}
        />
        <RouteWrapper
          exact
          path="/public/:id"
          backTo="https://bob-desk.fr/"
          component={PublicPage}
        />
        <RouteWrapper
          exact
          path="/public/:id/:scope"
          backTo="https://bob-desk.fr/"
          component={PublicPage}
        />
        <RouteWrapper
          exact
          path="/public/:id/:scope/:contractorId"
          backTo="https://bob-desk.fr/"
          component={PublicPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/desk'}
          layout={Dashboard}
          component={DeskPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/ged-test'}
          layout={Dashboard}
          component={GEDPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/safety'}
          layout={Dashboard}
          component={SafetyPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/safety/:id'}
          layout={Dashboard}
          component={SafetyPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/documents'}
          layout={Dashboard}
          component={DocumentPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/accounting'}
          layout={Dashboard}
          component={AccountingPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/propositions'}
          layout={Dashboard}
          component={ContractPropositionPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/propositions/:id'}
          layout={Dashboard}
          component={ContractPropositionPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/tickets'}
          layout={Dashboard}
          component={TicketPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/tickets/:id'}
          layout={Dashboard}
          component={TicketPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/calendar-new'}
          layout={Dashboard}
          component={CalendarPage2}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/calendar'}
          layout={Dashboard}
          component={CalendarPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/calendar/technician/:id'}
          layout={Dashboard}
          component={CalendarPage}
        />
        <RouteWrapper
          privateRoute
          exact
          maintenancePage
          path={'/maintenance-tickets'}
          layout={Dashboard}
          component={TicketPage}
        />
        <RouteWrapper
          privateRoute
          exact
          maintenancePage
          path={'/maintenance-tickets/:id'}
          layout={Dashboard}
          component={TicketPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/maintenances'}
          layout={Dashboard}
          component={MaintenancePage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/maintenances/:id'}
          layout={Dashboard}
          component={MaintenancePage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/maintenances-users'}
          layout={Dashboard}
          component={InterfaceMaintenancesUsersPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/maintenances-users/:id'}
          layout={Dashboard}
          component={InterfaceMaintenancesUsersPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/locations-list'}
          layout={Dashboard}
          component={LocationPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/locations-list/:id'}
          layout={Dashboard}
          component={LocationPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/equipments-list'}
          layout={Dashboard}
          component={EquipmentPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/equipments-list/:id'}
          layout={Dashboard}
          component={EquipmentPage}
        />
        <RouteWrapper
          exact
          path={'/locations/:id'}
          layout={({ children }) => children}
          component={() => <QRCodeTarget scope="locations" />}
        />
        <RouteWrapper
          exact
          path={'/equipments/:id'}
          layout={({ children }) => children}
          component={() => <QRCodeTarget scope="equipments" />}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/stocks'}
          layout={Dashboard}
          component={StockPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/stocks/:id'}
          layout={Dashboard}
          component={StockPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/interveners'}
          layout={Dashboard}
          component={IntervenerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/interveners/:id'}
          layout={Dashboard}
          component={IntervenerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/master/technicians'}
          layout={Dashboard}
          component={IntervenerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/master/technicians/:id'}
          layout={Dashboard}
          component={IntervenerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/customers'}
          layout={Dashboard}
          component={CustomerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/customers/:id'}
          layout={Dashboard}
          component={CustomerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/technicians'}
          layout={Dashboard}
          component={IntervenerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/technicians/:id'}
          layout={Dashboard}
          component={IntervenerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/marketplace'}
          layout={Dashboard}
          type={'marketplace'}
          component={IntervenerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/marketplace/:id'}
          layout={Dashboard}
          type={'marketplace'}
          component={IntervenerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/master/managers'}
          layout={Dashboard}
          component={ManagerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/managers'}
          layout={Dashboard}
          component={ManagerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/managers/:id'}
          layout={Dashboard}
          component={ManagerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/managers/all'}
          layout={Dashboard}
          component={ManagerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/managers/all/:id'}
          layout={Dashboard}
          component={ManagerPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/users'}
          layout={Dashboard}
          component={UserPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/users/:id'}
          layout={Dashboard}
          component={UserPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/clients'}
          layout={Dashboard}
          component={ClientsPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/clients/:id'}
          layout={Dashboard}
          component={ClientsPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/apis'}
          layout={Dashboard}
          component={ApiPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/apis/:id'}
          layout={Dashboard}
          component={ApiPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/meters'}
          layout={Dashboard}
          component={MeterPage}
        />
        <RouteWrapper
          privateRoute
          exact
          path={'/meters/:id'}
          layout={Dashboard}
          component={MeterPage}
        />
        <RouteWrapper
          privateRoute
          exact
          maintenancePage
          path={'/xx22'}
          layout={Dashboard}
          component={TestPage}
        />

        {/* Legacy redirects start */}
        <Redirect
          from="/agenda"
          to="/calendar"
        />
        <Redirect
          from="/intervenants"
          to="/interveners"
        />
        <Redirect
          from="/locations"
          to="/locations-list"
        />
        <Redirect
          from="/lieux"
          to="/locations-list"
        />
        <Redirect
          from="/inventaire"
          to="/inventory"
        />
        <Redirect
          from="/techniciens"
          to="/technicians"
        />
        <Redirect
          from="/utilisateurs"
          to="/users"
        />
        <Redirect
          from="/partner-apis"
          to="/apis"
        />
        <Redirect
          from="/bobteam"
          to="/accounting"
        />
        <Redirect
          from="/comptabilite"
          to="/master/managers"
        />
        <Redirect
          from="/contacts"
          to="/"
        />
        <Redirect
          from="/reset/:token"
          to="/set-password/:token"
        />
        <Redirect
          from="/reset"
          to="/reset-password"
        />
        <Redirect
          from="/ticket/:id"
          to="/tickets/:id"
        />
        <Redirect
          from="/equipements/:id"
          to="/equipments-list/:id"
        />
        <Redirect
          from="/équipements/:id"
          to="/equipments-list/:id"
        />
        <Redirect
          from="/equipments"
          to="/equipments-list"
        />
        <Redirect
          from="/equipements"
          to="/equipments-list"
        />
        <Redirect
          from="/équipements"
          to="/equipments-list"
        />
        {/* Legacy redirects end */}

        {/* Inscription page */}
        <Route
          path="/inscription"
          component={() => {
            if (!!window) window.location.href = 'https://bob-desk.fr/inscription-gmao';

            return null;
          }}
        />

        <RouteWrapper component={() => <FullScreenError />} />
      </Switch>
    </BrowserRouter>
  );
}
