import { checkSpiral } from 'entities/Ticket/Ticket';
import {
  CONTRACT_CANCELED,
  CONTRACT_CLOSED,
  CONTRACT_DECLINED,
  CONTRACT_DRAFT,
  CONTRACT_FINISHED,
  CONTRACT_INTERVENTION,
  CONTRACT_INTERVENTION_FINISH,
  CONTRACT_OPENED,
  CONTRACT_PROPOSED,
  CONTRACT_VISIT,
  CONTRACT_VISIT_DEVIS,
  PROPOSITION_ACCEPTED,
  PROPOSITION_CREATED
} from 'lists/contractStates';
import { trigger } from 'react-events-utils';
import { acceptContractProposition } from 'store/contractPropositionsSlice';
import {
  acceptDevis,
  acceptElement,
  closeElement,
  finishElement,
  toReplan,
  updateElement
} from 'store/ticketsSlice';

export const tActions = ({
  state,
  dispatch,
  isProposition,
  history,
  _id,
  _equipments,
  _jobs,
  _summons,
  role,
  closeFiles,
  auth,
  summonsIsCollaborator,
  summonsIsContractor,
  unvalidated_quotes,
  parent_has_interface,
  all_quotes_were_rejected,
  companyCreator,
  fromTimeline,
  afterDialogClose,
  setSelectedId,
  reports,
  contractParent
}) => {
  return [CONTRACT_DRAFT].includes(state)
    ? {
        order_publish: {
          show: true,
          action: (response) => {
            dispatch(updateElement, { draft: false }, {}, { id: _id });
          }
        }
      }
    : isProposition
      ? {
          contract_validation: {
            show: [PROPOSITION_CREATED].includes(state),
            action: (response) => {
              dispatch(
                acceptContractProposition,
                { response },
                {
                  onSuccess: () => {
                    if (response) {
                      history.push(`/tickets/${_id}`);
                    }
                  }
                },
                { id: _id }
              );
            }
          },
          open_validated_contract: {
            show: state === PROPOSITION_ACCEPTED
          }
        }
      : {
          order_validation: {
            show: role.permission('tickets', 'validate_ticket') && state === CONTRACT_OPENED,
            action: (response, reason) => {
              closeFiles();
              const cb = (assignToSpirale) =>
                dispatch(acceptElement, { response, reason, assignToSpirale }, {}, { id: _id });
              if (auth.interface.isBlachere && checkSpiral(_equipments)) {
                trigger('openSpiral', {
                  cb: (isSpiral) => {
                    if (isSpiral) {
                      cb(true);
                    } else {
                      cb(false);
                    }
                  }
                });
              } else {
                cb(false);
              }
            }
          },
          order_job: {
            show:
              role.permission('tickets', 'job') &&
              !!auth.interface._company._configuration.ticket_job_toassign &&
              !_jobs?.length &&
              ![
                CONTRACT_CANCELED,
                CONTRACT_DECLINED,
                CONTRACT_OPENED,
                CONTRACT_PROPOSED,
                CONTRACT_FINISHED,
                CONTRACT_CLOSED
              ].includes(state)
          },
          create_contract: {
            show:
              role.permission('tickets', 'assign_ticket') &&
              (!auth.interface._company._configuration.ticket_job_toassign || !!_jobs?.length) &&
              !_summons?.length &&
              !summonsIsContractor &&
              !summonsIsCollaborator &&
              ![
                CONTRACT_CANCELED,
                CONTRACT_DECLINED,
                CONTRACT_OPENED,
                CONTRACT_PROPOSED,
                CONTRACT_FINISHED,
                CONTRACT_CLOSED
              ].includes(state)
          },
          devis_validation: {
            show: !!unvalidated_quotes?.length && role.permission('tickets', 'validate_devis'),
            action: (response, reason, id) => {
              closeFiles();
              dispatch(
                acceptDevis,
                {
                  response,
                  reason,
                  isParent: Boolean(contractParent && !parent_has_interface),
                  id
                },
                {},
                { id: _id }
              );
            }
          },
          replan: {
            show:
              [
                CONTRACT_VISIT,
                CONTRACT_VISIT_DEVIS,
                CONTRACT_INTERVENTION,
                CONTRACT_INTERVENTION_FINISH
              ].includes(state) && role.permission('tickets', 'replan'),
            action: (replan_reason) => {
              dispatch(toReplan, { replan_reason }, {}, { id: _id });
            }
          },
          finish: {
            show:
              ([
                CONTRACT_INTERVENTION,
                CONTRACT_INTERVENTION_FINISH,
                CONTRACT_VISIT,
                CONTRACT_VISIT_DEVIS
              ].includes(state) ||
                all_quotes_were_rejected) &&
              role.permission('tickets', 'finish_ticket') &&
              (!companyCreator._configuration.ticket_ir_mandatory ||
                (companyCreator._configuration.ticket_ir_mandatory && Boolean(reports.length)) ||
                (auth.interface.isManager && auth.interface._company._id === companyCreator._id)),
            action: (leverReserve) => {
              dispatch(
                finishElement,
                { ...(leverReserve ? { reserve: true } : {}) },
                {},
                { id: _id }
              );
            }
          },
          close: {
            show:
              state === CONTRACT_FINISHED &&
              state !== CONTRACT_CANCELED &&
              role.permission('tickets', 'close'),
            action: () => {
              dispatch(
                closeElement,
                {},
                {
                  onSuccess: () => {
                    if (fromTimeline) {
                      afterDialogClose();
                      setSelectedId(null);
                    }
                  }
                },
                { id: _id }
              );
            }
          }
        };
};
