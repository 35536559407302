import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';

export default function NoResultData() {
  const { t } = useTranslation();

  return (
    <div className="text-center mt-10 mb-10">
      <FAIcon
        icon="tasks"
        style={{
          height: 75,
          width: 75
        }}
      />
      <h3 className="mt-2 text-sm font-medium text-gray-900">{t('noCommiteeSafetyResult')}</h3>
      <p className="mt-1 text-sm text-gray-500">{t('noSafetyCommiteeMessage')}</p>
    </div>
  );
}
