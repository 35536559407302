import Location from 'entities/Location/Location';
import Equipment from 'entities/Equipment/Equipment';
import Ticket from 'entities/Ticket/Ticket';
import Managers from './Tabs/Managers';
import Maintenance from 'entities/Maintenance/Maintenance';
import ContractorTabs from 'entities/Contractor/ContractorTabs';
import Documents from 'modules/documents/DMS';

const ClientsTabs = (config, element) => {
  return (
    !!element &&
    (element.isContractor
      ? ContractorTabs(config, element)
      : [
          {
            label: 'chargesDaffaires',
            icon: 'mail-bulk',
            count: element.partner?._managers?.filter((v) => !!v)?.length,
            show: !config?.isMaster && element.userInterface,
            render: () => (
              <Managers
                disableGutters
                element={element}
                picker
              />
            ),
            class: 'tabNoPadding'
          },
          {
            label: 'menuItemDocuments',
            icon: 'clouds',
            count: element.tabs?.docs || 0,
            show: config?.isContractor, // ? Is this correct? todo check
            render: (element, isLoading) => (
              <Documents
                endpoint_prefix={`client_documents/${element._id}`}
                tab_id={element.documents_tab_id}
              />
            )
          },
          {
            label: 'locations',
            key: 'locations',
            icon: 'map-marker-alt',
            count: element.tabs?.locations,
            show: true,
            render: () => (
              <Location
                disableCreate={!element?.partner?.permissions?.includes('location')}
                disableGutters
                defaultForm={{ _client: element }}
                defaultFilters={{ clients: [element] }}
              />
            ),
            class: 'tabNoPadding'
          },
          {
            label: 'entityTicketName',
            key: 'tickets',
            icon: 'files',
            count: element.tabs?.tickets,
            show: true,
            render: () => (
              <Ticket
                disableGutters
                defaultForm={{ _client: element }}
                defaultFilters={{ clients: [element], section: { closed: true } }}
                disableCreate={!element?.partner?.permissions?.includes('ticket')}
              />
            ),
            class: 'tabNoPadding'
          },
          {
            label: 'equipmentsTitle',
            icon: 'wrench-simple',
            count: element.tabs?.equipments,
            show: config.feature.equipment,
            render: () => (
              <Equipment
                disableGutters
                defaultForm={{ _client: element }}
                defaultFilters={{ clients: [element] }}
                disableCreate={!element?.partner?.permissions?.includes('equipment')}
              />
            ),
            class: 'tabNoPadding'
          },
          {
            label: 'maintenancesTitle',
            icon: 'heartbeat',
            count: element.tabs?.maintenances,
            show: config.feature.preventif,
            render: () => (
              <Maintenance
                disableGutters
                defaultForm={{ _client: element }}
                defaultFilters={{ clients: [element] }}
                disableCreate={!element?.partner?.permissions?.includes('preventif')}
              />
            ),
            class: 'tabNoPadding'
          }
        ]
    ).filter((e) => e.show)
  );
};

export default ClientsTabs;
