import Location from 'entities/Location/Location';
import Ticket from 'entities/Ticket/Ticket';

const UserTabs = (config, element) => {
  return (
    !!element &&
    [
      {
        label: 'locations',
        icon: 'map-marker-alt',
        count: element._locations?.length,
        show: true,
        render: () => (
          <Location
            disableGutters
            hiddenFilters={{ contacts: [element._id], only: element._locations }}
          />
        )
      },
      {
        label: config.isBlachere ? 'tickets' : 'menuItemInterventions',
        icon: 'files',
        count: element.tabs?.tickets,
        show: true,
        render: () => (
          <Ticket
            disableGutters
            hiddenFilters={{ contacts: [element._id] }}
          />
        )
      }
    ].filter((e) => e.show)
  );
};

export default UserTabs;
