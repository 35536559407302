import { DialogContent, Typography, DialogActions, Button } from '@material-ui/core';
import Dialog from 'components/Dialog';
import DialogTitle from 'components/dialogs/DialogTitle';
import { useTranslation } from 'react-i18next';

export const LiftTheReserveDialog = ({ open, onClose, reserve, action }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      isOpen={open}
      onClose={onClose}
    >
      <DialogTitle
        title={t('reserveLift')}
        onClose={onClose}
      />
      <DialogContent>
        <Typography>{t('wouldYouAlsoReserveTicket', { reserveNb: reserve.number })}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => [action(false), onClose()]}>{t('no')}</Button>
        <Button onClick={() => [action(true), onClose()]}>{t('yes')}</Button>
      </DialogActions>
    </Dialog>
  );
};
