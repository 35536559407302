import Equipment from 'entities/Equipment/Equipment';
import Location from 'entities/Location/Location';
import Manager from 'entities/Manager/Manager';
import Supplier from 'entities/Suppliers/Supplier';

const StockFilters = (configuration, defaultFilters) =>
  [
    {
      type: 'Section',
      label: 'replenishmentInProgress',
      color: '#31b55a',
      key: 'order',
      default: false,
      show: true
    },
    {
      type: 'Section',
      label: 'alertThresholdReached',
      color: 'orange',
      key: 'alert',
      default: false,
      show: true
    },
    {
      label: 'storageLocations',
      key: 'warehouses',
      type: 'Entity',
      params: {
        entity: Location,
        entityProps: {
          type: 'locations',
          hiddenFilters: {
            section: {
              location: false,
              facturation: false,
              warehouse: true
            }
          }
        }
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'managers',
      key: 'managers',
      type: 'Entity',
      params: {
        entity: Manager,
        entityProps: {
          hiddenFilters: {
            section: {
              global: false,
              local: true
            }
          }
        }
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'suppliers',
      key: 'suppliers',
      type: 'Entity',
      params: {
        entity: Supplier,
        entityProps: {
          products: [],
          deleted: false
        }
      },
      show: true,
      disabled: false,
      default: []
    },
    {
      label: 'location',
      key: 'locations',
      type: 'Entity',
      params: {
        entity: Location,
        entityProps: {
          type: 'locations',
          hiddenFilters: {
            section: {
              location: true,
              facturation: false
            }
          }
        }
      },
      show: true,
      disabled: false,
      default: []
    },

    {
      label: 'equipment',
      key: 'equipments',
      type: 'Entity',
      params: {
        entity: Equipment,
        entityProps: {}
      },
      show: true,
      disabled: false,
      default: []
    }
  ].filter((e) => e.show);

export const formatFilter = (filters) => filters;

export default StockFilters;
