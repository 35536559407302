import { createSlice } from '@reduxjs/toolkit';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import generateAsyncThunk from 'utils/generateAsyncThunk';

export const createElement = generateAsyncThunk({ type: 'POST', endpoint: 'users' });
export const updateElement = generateAsyncThunk({ type: 'PUT', endpoint: 'users/${id}' });
export const getElement = generateAsyncThunk({ type: 'GET', endpoint: 'users/${id}' });
export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'users/list' });
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'users/${id}' });
export const restoreElement = generateAsyncThunk({ type: 'POST', endpoint: 'users/recycle/${id}' });

const entityBoilerplate = generateEntityBoilerplate({
  createElement,
  updateElement,
  getElement,
  getElements,
  deleteElement,
  restoreElement
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const managersSlice = createSlice({
  name: 'managers',
  initialState,
  reducers,
  extraReducers
});

export const { flushElements, flushElement } = managersSlice.actions;
export default managersSlice.reducer;
