import { Box } from '@material-ui/core';

import { useState } from 'react';
import { useRole } from 'hooks/useRole';
import { useContract } from 'contexts/entities/contractContext';
import useStyles from 'layouts/entities/View.styles';

import { useHistory } from 'react-router';
import { useAuth } from 'hooks/useAuth';
import useFileViewer from 'hooks/useFileViewer';
import ReasonDialog from 'components/dialogs/ReasonDialog';
import { useConfiguration } from 'hooks/useConfiguration';
import { useEntity } from 'contexts/entities/entityContext';
import { isArray } from 'lodash-es';
import { tActions } from 'modules/tickets/actions';
import { LiftTheReserveDialog } from 'modules/tickets/hooks/LiftTheReserveDialog';
import OpenValidatedContract from 'modules/tickets/buttons/OpenValidatedContract';
import ContractValidation from 'modules/tickets/buttons/ContractValidation';
import ReplanButton from 'modules/tickets/buttons/ReplanButton';
import OrderAction from 'modules/tickets/buttons/OrderAction';
import OrderJob from 'modules/tickets/buttons/OrderJob';
import CreateContract from 'modules/tickets/buttons/CreateContract';
import DevisValidation from 'modules/tickets/buttons/DevisValidation';
import FinishButton from 'modules/tickets/buttons/FinishButton';
import CloseButton from 'modules/tickets/buttons/CloseButton';
import OrderPublish from 'modules/tickets/buttons/OrderPublish';

export default function TicketActionButtons() {
  const contract = useContract();
  const reports = contract.order?.reports || [];

  const {
    isProposition,
    contract: { state, _summons, devis, summonsIsCollaborator, summonsIsContractor },
    contractParent,
    order: { _jobs, _id, documents_validation, _equipments },
    companyCreator,
    dispatch,
    setIntervenerPicker,
    isLoading,
    _ticket_reserve
  } = contract;

  const parent_has_interface =
    contractParent && contractParent._company && contractParent._company.userInterface;
  const parent_quotes = contractParent && contractParent.devis;

  const auth = useAuth();

  const config = useConfiguration();
  const role = useRole();
  const history = useHistory();
  const { closeFiles } = useFileViewer();

  const { afterDialogClose, fromTimeline, setSelectedId } = useEntity();

  const [reasonOpen, setReasonOpen] = useState(false);

  const [liftReserveDialogIsOpen, setLiftReserveDialogIsOpen] = useState(false);

  const unvalidated_quotes = !isArray(devis)
    ? []
    : (parent_has_interface ? devis : devis.concat(parent_quotes || [])).filter(
        (devis) => !devis.deleted.state && !devis.validated.state && !devis.dropped.state
      );

  const all_quotes_were_rejected =
    devis?.length > 0 &&
    devis.filter((devis) => !devis.dropped.state || devis.deleted.state).length === 0;

  const classes = useStyles();

  const actions = tActions({
    state,
    dispatch,
    isProposition,
    history,
    _id,
    _equipments,
    _jobs,
    _summons,
    role,
    closeFiles,
    auth,
    summonsIsCollaborator,
    summonsIsContractor,
    unvalidated_quotes,
    parent_has_interface,
    all_quotes_were_rejected,
    companyCreator,
    fromTimeline,
    afterDialogClose,
    setSelectedId,
    reports,
    contractParent
  });

  return (
    <Box className={classes.viewActions}>
      {_ticket_reserve && actions.finish?.show && (
        <LiftTheReserveDialog
          reserve={_ticket_reserve}
          open={liftReserveDialogIsOpen}
          onClose={() => setLiftReserveDialogIsOpen(false)}
          action={actions.finish?.action}
        />
      )}

      <Box className={classes.viewActionsLeft}></Box>

      <Box
        className={classes.viewActionsRight}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-end'}
      >
        {actions.open_validated_contract?.show && (
          <OpenValidatedContract
            _id={_id}
            isLoading={isLoading}
            history={history}
            config={config}
          />
        )}

        {actions.contract_validation?.show && (
          <ContractValidation
            actions={actions}
            isLoading={isLoading}
          />
        )}

        {actions.replan?.show && (
          <ReplanButton
            actions={actions}
            isLoading={isLoading}
          />
        )}

        {actions.order_validation?.show && (
          <OrderAction
            documents_validation={documents_validation}
            setReasonOpen={setReasonOpen}
            actions={actions}
            isLoading={isLoading}
          />
        )}

        {actions.order_job?.show && (
          <OrderJob
            isLoading={isLoading}
            _jobs={_jobs}
            _id={_id}
          />
        )}

        {actions.create_contract?.show && (
          <CreateContract
            isLoading={isLoading}
            isProposition={isProposition}
            setIntervenerPicker={setIntervenerPicker}
          />
        )}

        {actions.devis_validation?.show && (
          <DevisValidation
            isLoading={isLoading}
            unvalidated_quotes={unvalidated_quotes}
            actions={actions}
            setReasonOpen={setReasonOpen}
          />
        )}

        {actions.finish?.show && (
          <FinishButton
            actions={actions}
            isLoading={isLoading}
            setLiftReserveDialogIsOpen={setLiftReserveDialogIsOpen}
          />
        )}

        {actions.close?.show && (
          <CloseButton
            actions={actions}
            isLoading={isLoading}
          />
        )}

        {actions.order_publish?.show && (
          <OrderPublish
            actions={actions}
            isLoading={isLoading}
          />
        )}
      </Box>
      <ReasonDialog
        open={!!reasonOpen}
        onClose={(e) => [setReasonOpen(false)]}
        onConfirm={(reason) => reasonOpen(reason)}
      />
    </Box>
  );
}
