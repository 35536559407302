import {
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Slider,
  TextField,
  Select,
  Typography,
  Tooltip
} from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import EntityX from 'components/inputs/EntityX';
import Equipment from 'entities/Equipment/Equipment';
import Client from 'entities/Client/Client';
import Intervener from 'entities/Intervener/Intervener';
import Job from 'entities/Job/Job';
import Location from 'entities/Location/Location';
import Manager from 'entities/Manager/Manager';
import { Field, useField, useFieldObserver } from 'frmx';
import useKeys from '@flowsn4ke/usekeys';
import DisplayMore from 'components/DisplayMore';
import periodicities from 'constants/periodicities';
import { useConfiguration } from 'hooks/useConfiguration';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';

///!\ COPY PreventifService back
const typeToAlert = (type) =>
  ({
    day: 2,
    week: 2,
    '2week': 4,
    '3week': 4,
    '6week': 4,
    month: 7,
    '2month': 7,
    trim: 14,
    qrim: 14,
    semestre: 30,
    year: 60,
    reduit: 60,
    complet: 30,
    '2year': 60,
    '3year': 60,
    '4year': 60,
    '5year': 60,
    '10year': 60
  })[type];

///!\ COPY PreventifService back
const typeToPlanning = (type) =>
  ({
    day: parseInt(2 * 1.5),
    week: parseInt(2 * 1.5),
    '2week': parseInt(4 * 1.5),
    '3week': parseInt(4 * 1.5),
    '6week': parseInt(4 * 1.5),
    month: parseInt(7 * 1.5),
    '2month': parseInt(7 * 1.5),
    trim: parseInt(14 * 1.5),
    qrim: parseInt(14 * 1.5),
    semestre: parseInt(30 * 1.5),
    year: parseInt(60 * 1.5),
    reduit: parseInt(60 * 1.5),
    complet: parseInt(30 * 1.5),
    '2year': parseInt(60 * 1.5),
    '3year': parseInt(60 * 1.5),
    '4year': parseInt(60 * 1.5),
    '5year': parseInt(60 * 1.5),
    '10year': parseInt(60 * 1.5)
  })[type];

export default function MaintenanceForm({ isCreate }) {
  const { value: compute, setValue: setCompute } = useField('compute');
  const { value: planningDaysValue, setValue: setPlanningDays } = useField('defaults.planning.day');
  const { value: alertDaysValue, setValue: setAlertDays } = useField('defaults.alert.day');
  const k = useKeys();
  const k1 = useKeys();

  const config = useConfiguration();
  const auth = useAuth();

  const { t } = useTranslation();

  // const period = useFieldObserver('defaults.config.type')
  const planDays = useFieldObserver('defaults.planning.day');
  const planEnabled = useFieldObserver('defaults.planning.enable');
  const alertDays = useFieldObserver('defaults.alert.day');
  const alertEnabled = useFieldObserver('defaults.alert.enable');

  const clients = useFieldObserver('_clients');

  const isClient = auth.interface.isClient;

  return (
    <>
      <Grid
        item
        xs={12}
        style={{ paddingTop: 0 }}
      >
        <ToggleButtonGroup
          style={{ width: '100%' }}
          exclusive
          value={compute}
          onChange={(e, next) => next && setCompute(next)}
        >
          {[
            {
              color: '#c0392b',
              label: t('regulatory'),
              value: 'regulatory',
              tooltip: t('regulatoryExplanation')
            },
            {
              color: '#2980b9',
              label: t('calendar'),
              value: 'calendar',
              tooltip: t('calendarExolanation')
            }
          ].map((el, i) => (
            <ToggleButton
              key={k(i)}
              style={{
                width: '100%',
                background: el.color,
                opacity: el.value === compute ? 1 : 0.8
              }}
              value={el.value}
              disabled={!isCreate}
            >
              {el.label}
              <Tooltip
                placement="left"
                title={el.tooltip}
              >
                <InfoOutlined
                  style={{
                    position: 'absolute',
                    right: 10
                  }}
                />
              </Tooltip>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field path="name">
          <TextField
            autoFocus
            variant="outlined"
            label={t('generatorName')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field
          path="defaults.config.type"
          afterChange={(period) => {
            setPlanningDays(typeToPlanning(period));
            setAlertDays(typeToAlert(period));
          }}
        >
          <Select
            fullWidth
            variant="outlined"
            placeholder={t('periodicity')}
          >
            {Object.keys(periodicities)
              .filter(
                (type) =>
                  (!config.isBlachere && !['reduit', 'complet'].includes(type)) || config.isBlachere
              )
              .map((p, i) => (
                <MenuItem
                  key={k1(i)}
                  value={p}
                >
                  {t(periodicities[p])}
                </MenuItem>
              ))}
          </Select>
        </Field>
      </Grid>

      {!isClient && (
        <Grid
          item
          xs={12}
        >
          <EntityX
            path="_clients"
            entityName={'clients'}
            entity={Client}
            entityProps={{
              hiddenFilters: {
                authorisations: ['preventif']
              }
            }}
            placeholder={t('clients')}
          />
        </Grid>
      )}

      {isCreate && (
        <Grid
          item
          xs={12}
        >
          <EntityX
            disabled={!clients.length && !isClient}
            disabledHelperText={t('pleaseSelectAClientFirst')}
            path="_locations"
            entityName="locations"
            entity={Location}
            entityProps={{
              lockClients: true,
              defaultFilters: { clients }
            }}
            placeholder={t('locationsTitle')}
          />
        </Grid>
      )}

      {config.feature.equipment && isCreate && (
        <Grid
          item
          xs={12}
        >
          <EntityX
            disabled={!clients.length && !isClient}
            disabledHelperText={t('pleaseSelectAClientFirst')}
            path="_equipments"
            entityName="equipments"
            entity={Equipment}
            entityProps={{
              lockClients: true,
              defaultFilters: { clients }
            }}
            placeholder={t('equipmentsTitle')}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        <EntityX
          path="defaults.intervener"
          entityName="contractors"
          entity={Intervener}
          entityProps={{
            hiddenFilters: {
              section: { mineM: false, collaborator: false, public: false, mine: true },
              isActive: false
            }
          }}
          placeholder={t('intervener')}
          pickerUniq
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <DisplayMore>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              <EntityX
                path="_managers"
                entityName="managers"
                entity={Manager}
                placeholder={t('managers')}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <EntityX
                path="defaults.job"
                entity={Job}
                placeholder={t('job')}
                pickerUniq
                entityProps={{}}
              />
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Field path="description">
                <TextField
                  variant="outlined"
                  label={t('additionalInformation')}
                  fullWidth
                  multiline
                  rows={4}
                  helperText={t('additionalInformationHelper')}
                />
              </Field>
            </Grid>
          </Grid>
        </DisplayMore>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Typography variant="h6">Options</Typography>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <FormControlLabel
          control={
            <Field
              path="defaults.autoAssign"
              type="checkbox"
            >
              <Checkbox />
            </Field>
          }
          label={t('autoAssignation')}
        />
        <FormHelperText>{t('autoAssignationExplanation')}</FormHelperText>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <FormControlLabel
          control={
            <Field
              path="defaults.planning.enable"
              type="checkbox"
            >
              <Checkbox />
            </Field>
          }
          label={t('planXDaysBeforePeriodEnd', { count: !planEnabled ? 0 : planDays })}
        />
        <FormHelperText>
          {t(
            config.isBlachere
              ? 'blachPlanXDaysBeforePeriodEndExplanation'
              : 'planXDaysBeforePeriodEndExplanation'
          )}
        </FormHelperText>
      </Grid>

      {planEnabled && (
        <Grid
          item
          xs={12}
        >
          <Typography gutterBottom>{t('notificationPlannificationDaysNumber')}</Typography>
          <Slider
            min={0}
            max={365}
            step={1}
            valueLabelDisplay="auto"
            value={planningDaysValue}
            onChange={(e, value) => setPlanningDays(value)}
          />
        </Grid>
      )}

      <Grid
        item
        xs={12}
      >
        <FormControlLabel
          control={
            <Field
              path="defaults.alert.enable"
              type="checkbox"
            >
              <Checkbox />
            </Field>
          }
          label={t('notificationBeforeXDays', { count: !alertEnabled ? 0 : alertDays })}
        />
        <FormHelperText>{t('notificationBeforeXDaysExplanation')}</FormHelperText>
      </Grid>

      {alertEnabled && (
        <Grid
          item
          xs={12}
        >
          <Typography gutterBottom>{t('notificationReminderDaysNumber')}</Typography>

          <Slider
            min={0}
            max={365}
            step={1}
            valueLabelDisplay="auto"
            value={alertDaysValue}
            onChange={(e, value) => setAlertDays(value)}
          />
        </Grid>
      )}
    </>
  );
}
