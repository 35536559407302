import {
  Button,
  Chip,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core';

import { deleteInterventionReport, liftTheReserve } from 'store/ticketsSlice';
import { useState } from 'react';

import { dateToLocalFormat } from 'utils/dates';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { useRole } from 'hooks/useRole';
import ConfirmDialog from 'components/dialogs/ConfirmDialog';
import FAIcon from 'components/ui/FAIcon';
import Ticket from 'entities/Ticket/Ticket';
import { trim } from 'utils/trim';
import BlurredProgress from 'components/BlurredProgress';
import useFileViewer from 'hooks/useFileViewer';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import axios from 'axios';
import { apiBaseURL } from 'index';
import useConfirm from 'hooks/useConfirm';

export default function ReportItem({
  ticketId,
  deletable,
  report,
  company,
  setFormIsOpen,
  setSelectedTemplate,
  setSelectedReport
}) {
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [liftReserveIsOpen, setLiftReserveIsOpen] = useState(false);

  const { dispatch, requestStatus } = useAsyncDispatch();
  const notify = useNotifications();
  const dispatchCallbacks = ({ success, error }) => ({
    onSuccess: () => notify.success(success),
    onError: () => notify.error(error)
  });

  const { t } = useTranslation();

  const { openFiles } = useFileViewer();

  const deleteReport = () =>
    dispatch(
      deleteInterventionReport,
      undefined,
      dispatchCallbacks({ success: t('reportWasIndeedDeleted') }),
      { id: report._id, ticketId }
    );
  const openInEditor = () => [
    setSelectedReport(report),
    setSelectedTemplate(report._template),
    setFormIsOpen(true)
  ];
  const openPDF = () => openFiles([report._document]);

  const liftThisReserve = () =>
    dispatch(
      liftTheReserve,
      undefined,
      dispatchCallbacks({ success: t('reserveWasIndeedLifted') }),
      { id: report._id, _ticket: ticketId }
    );

  const sendReportToClient = async () => {
    const report_id = report?._id;

    return axios
      .post(`${apiBaseURL}/ir2/${report_id}/send-ir-to-client`, { ticket_id: ticketId })
      .then((res) => {
        return res.status === 204
          ? notify.error(t('emailNotSentMakeSureYourRecipientHasEmail'))
          : notify.success(t('reportWasSent'));
      })
      .catch(() => notify.error());
  };

  const { permission } = useRole();
  const canEditReports = permission('reports', 'update_report');
  const canDeleteReports = permission('reports', 'delete_report');
  const canLiftReserve = permission('maintenances', 'lift_reserve');

  const isCustomReport = report?.loaded;
  const isContractor = useAuth().interface.isContractor;

  const confirm = useConfirm();

  return (
    <>
      <ConfirmDialog
        open={deleteIsOpen}
        onClose={() => setDeleteIsOpen(false)}
        onConfirm={() => deleteReport()}
        text={t('sureYouWantToDeleteThisReport')}
      />
      {isCustomReport && (
        <ConfirmDialog
          open={liftReserveIsOpen}
          onClose={() => setLiftReserveIsOpen(false)}
          onConfirm={() => liftThisReserve()}
          text={t('sureYouWantToLiftTheReserve')}
        />
      )}

      <ListItem
        button
        disabled={requestStatus === 'loading'}
        onClick={!!report?.finished_at || isCustomReport ? openPDF : openInEditor}
      >
        <BlurredProgress in={requestStatus === 'loading'} />
        <ListItemAvatar style={{ marginLeft: '1em' }}>
          <FAIcon
            style={{
              color:
                (isCustomReport && !report?.reserve) || !!report?.finished_at
                  ? 'green'
                  : isCustomReport && report?.reserve
                    ? 'red'
                    : ''
            }}
            collection="fal"
            icon={
              isCustomReport && report?.reserve
                ? 'ban'
                : isCustomReport && !report?.reserve
                  ? 'circle-check'
                  : report.signatureDate?.client && report.signatureDate?.contractor
                    ? 'check-double'
                    : !report.signatureDate?.client && !report.signatureDate?.contractor
                      ? 'hourglass-start'
                      : 'check'
            }
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            isCustomReport ? (
              <>
                {report._document?.value
                  ? trim(
                      report._document?.value?.slice(0, report._document?.value?.lastIndexOf('.'))
                    ) + report._document?.value?.slice(report._document?.value?.lastIndexOf('.'))
                  : report._document?.name}
              </>
            ) : (
              <>
                <Typography
                  variant="subtitle2"
                  component="span"
                >
                  {report._template.label} (
                  {t('createdOnX', {
                    date: dateToLocalFormat(new Date(report.created_at), 'PPPp')
                  })}
                  )
                </Typography>
              </>
            )
          }
          secondary={
            isCustomReport ? (
              <>
                {t(
                  report.reserve
                    ? 'withReserves'
                    : report.reserveDate
                      ? 'reserveLifted'
                      : 'withoutReserves'
                )}
                {report?.urgency > 0 && (
                  <Chip
                    style={{
                      marginLeft: '.5em',
                      color: { 1: '#F39C12', 2: 'red', 3: 'black' }[report?.urgency],
                      borderColor: { 1: '#F39C12', 2: 'red', 3: 'black' }[report?.urgency]
                    }}
                    variant="outlined"
                    label={{ 1: t('weak'), 2: t('notSoWeak'), 3: t('critical') }[report?.urgency]}
                    size="small"
                  />
                )}
              </>
            ) : (
              <>
                {report.signatureDate.client && (
                  <>
                    <Typography
                      variant="caption"
                      component="span"
                    >
                      {t('signedByXOnY', {
                        name: report.signatureName.client + ' ' + company.name,
                        date: dateToLocalFormat(new Date(report.signatureDate.client), 'PPPp')
                      })}
                    </Typography>
                    <br />
                  </>
                )}

                {report.signatureDate.contractor && (
                  <>
                    <Typography
                      variant="caption"
                      component="span"
                      style={{ lineHeight: '.5em' }}
                    >
                      {t('signedByXOnY', {
                        name: report.signatureName.contractor,
                        date: dateToLocalFormat(new Date(report.signatureDate.contractor), 'PPPp')
                      })}
                    </Typography>
                  </>
                )}
              </>
            )
          }
        />
        <ListItemSecondaryAction style={{ marginRight: '1em' }}>
          {canLiftReserve && isCustomReport && !!report?.reserve && (
            <Button
              size="small"
              onClick={() => setLiftReserveIsOpen(true)}
            >
              {t('liftTheReserve')}
            </Button>
          )}
          {isContractor && (
            <IconButton
              size="small"
              onClick={() =>
                confirm({
                  title: 'sureYouWantToSendThisReportToTheClient',
                  onConfirm: () => sendReportToClient()
                })
              }
            >
              <FAIcon
                size="small"
                collection="fal"
                icon="paper-plane"
              />
            </IconButton>
          )}
          {report?._ticket_link && (
            <IconButton>
              <Ticket childrenId={report?._ticket_link}>
                <FAIcon
                  size="small"
                  collection="fal"
                  icon="files"
                />
              </Ticket>
            </IconButton>
          )}
          {!!report?.finished_at || isCustomReport ? (
            <IconButton onClick={openPDF}>
              <FAIcon
                size="small"
                collection="fal"
                icon="arrow-up-right-from-square"
              />
            </IconButton>
          ) : !report?.finished_at && !isCustomReport && canEditReports ? (
            <IconButton onClick={openInEditor}>
              <FAIcon
                size="small"
                collection="fal"
                icon="pen-to-square"
              />
            </IconButton>
          ) : null}
          {canDeleteReports && deletable && (
            <IconButton onClick={() => setDeleteIsOpen(true)}>
              <FAIcon
                size="small"
                collection="fal"
                icon="trash-can"
              />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
}
