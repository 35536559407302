import {
  Button,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Grid,
  Box
} from '@material-ui/core';
import { createInterventionReport, updateInterventionReport } from 'store/ticketsSlice';

import useKeys from '@flowsn4ke/usekeys';
import { Form, useFieldObserver, useForm } from 'frmx';
import { useMemo, useState, useRef, useEffect } from 'react';
import SignatureField from 'components/inputs/SignatureField';
import FAIcon from 'components/ui/FAIcon';
import { fieldTypes } from 'fieldSections/fieldTypes';
import { isBase64 } from 'validation/isBase64';

import DialogTitle from 'components/dialogs/DialogTitle';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import EndInterventionDialog from './EndInterventionDialog';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import { useConfiguration } from 'hooks/useConfiguration';
import AttachmentField from 'components/inputs/AttachmentField';
import { useIsBelowMd } from 'hooks/useMQ';

export default function ReportEditor({ formIsOpen, onClose, template, report, ticket }) {
  const [closeTicketDialogIsOpen, setCloseTicketDialogIsOpen] = useState(false);
  const isEditable = !(report?.signatureDate?.contractor || report?.signatureDate?.client);
  const [canEdit, setCanEdit] = useState(isEditable);
  const [canSign, setCanSign] = useState(!isEditable);
  const auth = useAuth();

  const client_signature_contractor_access = !!template?.client_signature_contractor_access;
  const client_signature_mandatory = !!template?.client_signature_mandatory;

  const config = useConfiguration();

  const irIsMandatory = ticket.companyCreator?._configuration?.ticket_ir_mandatory;

  const { t } = useTranslation();

  const { dispatch, requestStatus } = useAsyncDispatch({ virtualized: true });
  const notify = useNotifications();

  const initialFields = useMemo(
    () =>
      template &&
      template.fields.reduce((acc, curr) => {
        const val = !!report
          ? report?.fields?.find((r) => r._field === curr._id)?.value
          : curr?.extra?.defaultValue
            ? curr.extra.defaultValue
            : null;

        return (curr.valueType === 'date' || curr.valueType === 'date_finish') && !val
          ? { ...acc, [curr._id]: new Date() }
          : curr.valueType !== 'autofill'
            ? { ...acc, [curr._id]: val }
            : acc;
      }, {}),
    [template]
  );

  const mandatoryFields = useMemo(
    () =>
      template &&
      template.fields.reduce((acc, curr) => {
        return curr.valueType !== 'autofill' && !!curr?.required
          ? { ...acc, [curr._id]: (v) => v !== null }
          : acc;
      }, {}),
    [template]
  );

  const dispatchCallbacks = {
    onSuccess: () => notify.success(t('savedReport')),
    onError: () => notify.error()
  };

  const dateIds = useRef({ date_begin: null, date_finish: null });
  const [dates, setDates] = useState({ date_begin: null, date_finish: null });

  const handleSubmit = ({ data, draft }) => {
    const formattedData = {
      ...data,
      _template: template._id,
      _ticket: ticket._id,
      fields: Object.keys(data.fields).map((fieldId) => ({
        _field: fieldId,
        value: data.fields[fieldId]
      })),
      isFinish: !draft,
      attachments: data.attachments?.filter((f) => !(f instanceof File) && f) // filter only file has been uploaded
    };

    const formData = new FormData();
    formData.append('interventionReport', JSON.stringify(formattedData));

    // Attach the files to formData as per v4
    if (data.attachments?.length) {
      data.attachments.forEach((f, i) => {
        // append only files is not been uploaded
        if (f instanceof File) {
          formData.append(`file_${i}`, f);
        }
      });
    }

    dispatch(
      !!report ? updateInterventionReport : createInterventionReport,
      { formData },
      dispatchCallbacks,
      !!report && { id: report._id, ticketId: ticket._id }
    ).then(({ data, error }) => {
      if (!error) {
        const date_begin = data?.fields.find((f) => f._field === dateIds.current.date_begin);
        const date_finish = data?.fields.find((f) => f._field === dateIds.current.date_finish);

        setDates({
          date_begin: date_begin?.value,
          date_finish: date_finish?.value
        });

        if (irIsMandatory && !draft) {
          setCloseTicketDialogIsOpen(true);
        } else {
          onClose();
        }
      }
    });
  };

  const k = useKeys();
  const deviceIsMobile = useIsBelowMd();

  const clientSuggestion = auth.interface.isClient
    ? auth.interface?._company?.name
    : ticket.contractParent?._company?.name;

  const techniciansSuggestion =
    ticket?.contract?._summons?.map((s) => s?.isCollaborator && s?.firstName + ' ' + s?.lastName) ||
    [];

  const contractorSuggestion = auth.interface.isClient
    ? ticket?.contract?._summons?.map((s) =>
        s.isContractor ? s.companyName : s.firstName + ' ' + s.lastName
      ) || []
    : ticket?.contract?._summons?.every((s) => s?.isCollaborator)
      ? techniciansSuggestion
      : [];

  !auth.interface.isClient &&
    auth.interface?._company?.name &&
    contractorSuggestion.push(auth.interface?._company?.name);

  return template ? (
    <Form
      disabled={!canEdit || requestStatus === 'loading'}
      disableIfInvalid
      afterChange={(fields, updatedFieldPath, hasErrors, errors) => {
        if (!!Array.from(errors).find((k) => !k.startsWith('sign'))) setCanSign(false);
        else setCanSign(true);
      }}
      initialValues={{
        ...(template.client_signature || template.contractor_signature
          ? {
              signature: {
                contractor: report?.signature?.contractor || '',
                client: report?.signature?.client || ''
              },
              signatureDate: {
                contractor: report?.signatureDate?.contractor || null,
                client: report?.signatureDate?.client || null
              },
              signatureName: {
                contractor: report?.signatureName?.contractor || '',
                client: report?.signatureName?.contractor || ''
              }
            }
          : {}),
        fields: initialFields,
        attachments: report?.attachments || [],
        isFinish: null
      }}
      // TODO: Check signature access (client_signature_contractor_access && contractor_signature_client_access) whatever this means
      schemaValidation={{
        ...((template.client_signature || template.contractor_signature) &&
        (template.client_signature_mandatory || template.contractor_signature_mandatory)
          ? {
              signature: {
                ...(template.contractor_signature_mandatory
                  ? { contractor: (v) => isBase64(v) }
                  : {}),
                ...(template.client_signature_mandatory ? { client: (v) => isBase64(v) } : {})
              }
            }
          : {}),
        fields: mandatoryFields
      }}
      onSubmit={(data) => handleSubmit({ data: data, draft: false })}
    >
      <EndInterventionDialog
        ticket={ticket}
        open={closeTicketDialogIsOpen}
        onClose={() => [setCloseTicketDialogIsOpen(false), onClose()]}
        dates={dates}
      />
      <Dialog
        open={formIsOpen && !!template}
        scroll="paper"
        maxWidth={deviceIsMobile ? undefined : 'md'}
        fullWidth={!deviceIsMobile}
        fullScreen={deviceIsMobile}
        onClose={onClose}
      >
        <DialogTitle
          title={template?.label}
          onClose={onClose}
        />

        <DialogContent dividers>
          <Grid
            container
            spacing={2}
          >
            {/* !TODO: Do better than a map that does not always return something  */}
            {template.fields.map((f, i) => {
              const FieldComponent = fieldTypes[f.valueType].fieldComponent;

              // TODO: Display autofill values
              // Autofill components are also injected on the backend
              // We don't need to display them here... for now

              if (f.valueType === 'date_begin') {
                dateIds.current = { ...dateIds.current, date_begin: f._id };
              }
              if (f.valueType === 'date_finish') {
                dateIds.current = { ...dateIds.current, date_finish: f._id };
              }

              if (f.valueType !== 'autofill') {
                return (
                  <Grid
                    item
                    xs={12}
                    key={k(i)}
                  >
                    {FieldComponent && (
                      <FieldComponent
                        customField={f}
                        path={`fields.${f._id}`}
                        required={f.required}
                      />
                    )}
                  </Grid>
                );
              }
            })}

            {template.client_signature && (
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  variant="subtitle2"
                  style={{ marginBottom: 4, marginLeft: 4 }}
                >
                  {t('client')}
                </Typography>
                <SignatureField
                  suggestion={clientSuggestion}
                  signaturePath="signature.client"
                  datePath="signatureDate.client"
                  namePath="signatureName.client"
                  disabled={
                    !canSign ||
                    requestStatus === 'loading' ||
                    (config?.isContractor && !client_signature_contractor_access)
                  }
                />
              </Grid>
            )}

            {template.contractor_signature && (
              <Grid
                item
                xs={12}
                sm={6}
              >
                <Typography
                  variant="subtitle2"
                  style={{ marginBottom: 4, marginLeft: 4 }}
                >
                  {t('intervener')}
                </Typography>
                <SignatureField
                  suggestion={contractorSuggestion}
                  signaturePath="signature.contractor"
                  datePath="signatureDate.contractor"
                  namePath="signatureName.contractor"
                  disabled={!canSign || requestStatus === 'loading'}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <ReportSubmitButtons
            irIsMandatory={irIsMandatory}
            setCanSign={setCanSign}
            requestStatus={requestStatus}
            canEdit={canEdit}
            setCanEdit={setCanEdit}
            mandatoryFields={mandatoryFields}
            handleSubmit={handleSubmit}
            clientSignatureMandatory={client_signature_mandatory}
          />
        </DialogActions>
      </Dialog>
    </Form>
  ) : null;
}

function ReportSubmitButtons({
  handleSubmit,
  setCanSign,
  canEdit,
  setCanEdit,
  irIsMandatory,
  requestStatus,
  clientSignatureMandatory
}) {
  const { getFields, getErrors, handleSubmit: frmxSubmit } = useForm();
  const contractorSignature = useFieldObserver('signatureName.contractor');
  const clientSignature = useFieldObserver('signatureName.client');

  const { t } = useTranslation();

  const [canDraft, setCanDraft] = useState();

  useEffect(() => {
    if (getErrors && !!Array.from(getErrors()).find((k) => !k.startsWith('signature')))
      setCanSign(false);
    else setCanSign(true);
  }, []);

  useEffect(() => {
    setCanEdit(!(contractorSignature || clientSignature));
    setCanDraft(
      !!(
        (!contractorSignature && clientSignature) ||
        (contractorSignature && !clientSignature) ||
        (!contractorSignature && !clientSignature)
      )
    );
  }, [contractorSignature, clientSignature]);

  return (
    <Box>
      <AttachmentField
        path="attachments"
        disabled={requestStatus === 'loading'}
        multiple
        accept="image/*"
        icon="images"
      />

      <Button
        disabled={(!canEdit && !canDraft) || requestStatus === 'loading'}
        onClick={() => handleSubmit({ data: getFields(), draft: true })}
        startIcon={
          <FAIcon
            icon="save"
            collection="fad"
          />
        }
      >
        {t('save2')}
      </Button>

      <Button
        disabled={
          requestStatus === 'loading' ||
          (irIsMandatory &&
            ((!clientSignature && clientSignatureMandatory) || !contractorSignature))
        }
        onClick={() => frmxSubmit()}
        startIcon={
          <FAIcon
            icon={requestStatus === 'loading' ? 'spinner' : 'vote-yea'}
            collection={requestStatus === 'loading' ? 'fas' : 'fad'}
            className={requestStatus === 'loading' ? 'fa-spin' : ''}
          />
        }
      >
        {t('endVerb')}
      </Button>
    </Box>
  );
}
