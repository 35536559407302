import { isEmail } from 'validator';

const ManagerFormValues = (configuration) => ({
  firstName: '',
  lastName: '',
  _role: null,
  _locations: [],
  email: '',
  phone: '',
  fix: '',
  lang: ''
});
export default ManagerFormValues;

export const formatForm = (dataFromForm, isCreate, attachments) => {
  if (!!attachments?.length) {
    dataFromForm.avatar = attachments[0].url;
  }

  return { ...dataFromForm };
};

export const ManagerValidation = (configuration) => ({
  _role: (value) => !!value,
  email: (value, formData) => isEmail(value)
});
