import {
  Dialog,
  Box,
  List,
  Button,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  IconButton,
  useTheme,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Tooltip,
  Fab,
  Typography
} from '@material-ui/core';
import { Empty } from 'layouts/entities/List';
import { useMemo, useState } from 'react';
import DialogTitle from 'components/dialogs/DialogTitle';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Form, Submit, Field, useField, useFieldObserver } from 'frmx';
import { makeStyles } from '@material-ui/styles';
import EntityX from 'components/inputs/EntityX';
import Job from 'entities/Job/Job';
import Location from 'entities/Location/Location';
import { createAutoAssign, updateAutoAssign, deleteAutoAssign } from 'store/intervenersSlice';
import FAIcon from 'components/ui/FAIcon';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import useKeys from '@flowsn4ke/usekeys';
import BlurredProgress from 'components/BlurredProgress';
import { useRole } from 'hooks/useRole';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({ fieldItem: { margin: '1em 0', width: '100%' } }));

// TODO: Inject the autoassigns key on the back
export default function AutoAssignation({ intervener, kind }) {
  const [editorIsOpen, setEditorIsOpen] = useState(false);
  const [automatisation, setAutomatisation] = useState(null);
  const [conflict, setConflict] = useState(null);

  const k = useKeys();
  const { t } = useTranslation();

  return (
    <Box
      style={{
        backgroundColor: 'white',
        height: '100%'
      }}
    >
      <AutomatisationConflict
        open={!!conflict}
        conflict={conflict}
        onClose={() => setConflict(null)}
      />
      <AutoAssignationEditor
        kind={kind}
        intervener={intervener}
        setConflict={setConflict}
        editorIsOpen={editorIsOpen}
        automatisation={automatisation}
        onClose={() => [setEditorIsOpen(false), setAutomatisation(null)]}
      />
      {(() => {
        if (intervener?.autoassigns?.length) {
          return (
            <>
              <List style={{ paddingTop: 0 }}>
                {intervener.autoassigns.map((a, i) => (
                  <AutomatisationItem
                    key={k(i)}
                    automatisation={a}
                    edit={() => [setAutomatisation(a), setEditorIsOpen(true)]}
                    intervener={intervener}
                  />
                ))}
              </List>
              <Fab
                onClick={() => setEditorIsOpen((prev) => !prev)}
                color="primary"
                size="small"
                style={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16
                }}
              >
                <FAIcon icon="plus" />
              </Fab>
            </>
          );
        } else {
          return (
            <>
              <Empty
                icon="bolt"
                collection="fas"
                translations={{
                  noResultLabel: t('empty-automatisations'), // Auncune automatisation
                  noResultText: t('empty-text-automatisation') // Vous n'avez pas encore ajouté d'automatisation
                }}
              />
              <Fab
                onClick={() => setEditorIsOpen((prev) => !prev)}
                color="primary"
                size="small"
                style={{
                  position: 'absolute',
                  bottom: 16,
                  right: 16
                }}
              >
                <FAIcon icon="plus" />
              </Fab>
            </>
          );
        }
      })()}
    </Box>
  );
}

function AutomatisationItem(props) {
  const { automatisation, edit, intervener } = props;
  const [isActive, setIsActive] = useState(automatisation?.active || false);

  const { t } = useTranslation();

  const { permission } = useRole();

  const notify = useNotifications();
  const { dispatch, requestStatus } = useAsyncDispatch();
  const dispatchCallbacks = (success) => ({
    onSuccess: () => notify.success(success || t('editedAutomatisation')),
    onError: () => notify.error()
  });

  return (
    <ListItem
      button={permission('automatisations', 'update')}
      onClick={() => permission('automatisations', 'update') && edit()}
    >
      <BlurredProgress in={requestStatus === 'loading'} />
      <ListItemAvatar>
        <FAIcon
          collection="fal"
          icon="user-helmet-safety"
        />
      </ListItemAvatar>

      <ListItemText
        primary={t(automatisation?._job?.name) || ''}
        secondary={
          automatisation?.allLocations ? (
            t('allTheLocations')
          ) : automatisation?._locations?.length === 1 ? (
            automatisation._locations[0].name
          ) : (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <FAIcon
                collection="fal"
                icon="map-marker-alt"
                size="small"
              />
              {`${automatisation._locations?.length} lieux`}
            </span>
          )
        }
      />

      <ListItemSecondaryAction>
        <Tooltip title={t(isActive ? 'autoIsActivated' : 'autoIsDeactivated')}>
          <Switch
            disabled={requestStatus === 'loading' || !permission('automatisations', 'update')}
            checked={isActive}
            onChange={(e) => {
              const next = e.target.checked;
              setIsActive(next);
              dispatch(updateAutoAssign, { active: next }, dispatchCallbacks(), {
                id: intervener._id,
                automatisationIdWithSlash: '/' + automatisation._id
              });
            }}
          />
        </Tooltip>
        {permission('automatisations', 'update') && (
          <IconButton onClick={() => edit()}>
            <FAIcon
              collection="fas"
              icon="pencil"
            />
          </IconButton>
        )}
        {permission('automatisations', 'delete') && (
          <IconButton
            onClick={() => {
              dispatch(deleteAutoAssign, undefined, dispatchCallbacks(t('autoIsDeleted')), {
                id: automatisation._id,
                intervenerId: intervener._id
              });
            }}
          >
            <FAIcon
              collection="fas"
              icon="trash"
            />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
}

function AutomatisationConflict(props) {
  const { open, onClose, conflict } = props;
  const { t } = useTranslation();

  const k = useKeys();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle
        title={t('automatisationConflict')}
        onClose={onClose}
      />
      <DialogContent>
        <Typography>
          {t('conflict')}{' '}
          {conflict?._intervener && (
            <>
              {t('with2')}{' '}
              <strong>
                {conflict._intervener?.companyName ||
                  `${conflict._intervener?.firstName} ${conflict._intervener?.lastName}`}{' '}
              </strong>
            </>
          )}
          {conflict?._job && (
            <>
              {t('onActivityOf')} <strong>{t(conflict._job.name)} </strong>
            </>
          )}
          {t('forTheFollowingLocations')}{' '}
          {conflict?._locations?.length && (
            <>
              <ul>
                {conflict._locations.map((l, i) => (
                  <li key={k(i)}>{l.name}</li>
                ))}
              </ul>
            </>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}

function AutoAssignationEditor(props) {
  const { kind, editorIsOpen, onClose, intervener, setConflict, automatisation } = props;

  const isCreate = !automatisation?._id;

  const notify = useNotifications();
  const { dispatch, requestStatus } = useAsyncDispatch();
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={editorIsOpen}
      onClose={onClose}
    >
      <Form
        disabled={requestStatus === 'loading'}
        initialValues={{
          type: automatisation?.type || 'assign',
          active: automatisation?.active || true,
          allLocations: automatisation?.allLocations || false,
          _job: automatisation?._job || null,
          _locations: automatisation?._locations || [],
          kind
        }}
        schemaValidation={{
          type: (v) => !!v?.length
        }}
        onSubmit={(data) => {
          dispatch(
            isCreate ? createAutoAssign : updateAutoAssign,
            {
              ...data,
              _job: data._job?._id,
              _locations: data._locations.map((l) => l._id)
            },
            {
              onError: () => notify.error()
            },
            {
              id: intervener._id,
              automatisationIdWithSlash: isCreate ? undefined : '/' + automatisation._id
            }
          ).then(({ data, error }) => {
            if (!error) {
              if (data.autoassign.conflict) {
                const responseLocationsIds = data.autoassign.conflict?._locations?.map(
                  (l) => l._id
                );

                if (responseLocationsIds) {
                  const _locations = (
                    data?._locations || data?.autoassign?.conflict?._locations
                  )?.filter((l) => responseLocationsIds.includes(l._id));
                  const _job = data.autoassign.conflict._job;
                  const _intervener = data.autoassign.conflict._intervener;

                  setConflict({
                    ...data.autoassign.conflict,
                    ...(_locations ? { _locations } : {}),
                    ...(_job ? { _job } : {}),
                    ...(_intervener ? { _intervener } : {})
                  });
                }

                onClose();
              } else {
                notify.success(isCreate ? t('createdAutomatisation') : t('editedAutomatisation'));
                onClose();
              }
            }
          });
        }}
      >
        <DialogTitle
          title={t('createAutomatisation')}
          onClose={onClose}
        />
        <DialogContent>
          <AutoAssignationDialogContents intervener={intervener} />
        </DialogContent>
        <DialogActions>
          <Submit>
            <Button
              endIcon={
                <FAIcon
                  collection="fas"
                  icon="bolt"
                />
              }
            >
              {t('save2')}
            </Button>
          </Submit>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

function AutoAssignationDialogContents({ intervener }) {
  const { value: type, setValue: setType } = useField('type');
  const allLocations = useFieldObserver('allLocations');

  // TODO: Check that jobs are correctly filtered
  const intervenerJobIds = useMemo(() => intervener._jobs.map((i) => i?._id), [intervener?._id]);

  const { t } = useTranslation();

  const theme = useTheme();
  const c = useStyles();

  return (
    <>
      <ToggleButtonGroup
        className={c.fieldItem}
        exclusive
        value={type}
        onChange={(e, next) => next !== null && setType(next)}
        size="small"
      >
        <ToggleButton
          style={{
            width: '100%',
            fontSize: 11,
            color: type === 'assign' ? 'white' : theme.palette.secondary.main,
            backgroundColor: type === 'assign' ? theme.palette.secondary.main : ''
          }}
          value="assign"
        >
          {t('assignation')}
        </ToggleButton>
        <ToggleButton
          style={{
            width: '100%',
            fontSize: 11,
            backgroundColor: type === 'propose' ? theme.palette.secondary.main : '',
            color: type === 'propose' ? 'white' : theme.palette.primary.main
          }}
          value="propose"
        >
          {t('proposal')}
        </ToggleButton>
      </ToggleButtonGroup>

      <Box className={c.fieldItem}>
        <EntityX
          path="_job"
          entity={Job}
          entityName="jobs"
          placeholder={t('entityJobName')}
          pickerUniq
          entityProps={{
            hiddenFilters: { ids: intervenerJobIds }
          }}
        />
      </Box>

      <FormControlLabel
        control={
          <Field
            path="allLocations"
            type="checkbox"
          >
            <Checkbox />
          </Field>
        }
        label={t('selectAllLocations')}
      />

      <Box className={c.fieldItem}>
        <EntityX
          disabled={allLocations}
          disabledHelperText={t('allLocationsAlreadySelected')}
          path="_locations"
          entityName="locations"
          entity={Location}
          entityProps={{
            defaultFilters: {
              zone_ids: intervener?.zone_ids.length > 0 ? intervener?.zone_ids : []
            }
            // hiddenFilters: {
            //   zone_ids: intervener?.zone_ids.length > 0 ? intervener?.zone_ids : []
            // }
          }}
          placeholder={t('locations')}
          showAll
        />
      </Box>
    </>
  );
}
