import { Box, Button } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import React, { useState } from 'react';
import useFileViewer from 'hooks/useFileViewer';
import classNames from 'utils/classNames';
import useStyles from 'layouts/entities/View.styles';
import { useTranslation } from 'react-i18next';
import { Visibility } from '@material-ui/icons';
import { FileActions } from '../files/FileActions';
import { KINGFISHER_DEMO_ACCOUNT_ID, KINGFISHER_DEMO_ACCOUNT_TICKET_DOMAIN_INVESTISSEMENT_ID } from 'index';
import { useAuth } from 'hooks/useAuth';
import { useEntity } from 'contexts/entities/entityContext';
import { useContract } from 'contexts/entities/contractContext';
import useKeys from '@flowsn4ke/usekeys';
import { formatCurrency } from 'utils/formatCurrency';

const DevisValidation = ({ isLoading, unvalidated_quotes, actions, setReasonOpen }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { openFiles, setFilesActions } = useFileViewer();
  const { element } = useEntity();
  const auth = useAuth();
  const contract = useContract();
  const k = useKeys();
  const [king_fisher_fake_double_validation, set_king_fisher_fake_double_validation] = useState(false);

  const is_king_fisher_investment_request =
    KINGFISHER_DEMO_ACCOUNT_TICKET_DOMAIN_INVESTISSEMENT_ID === element.category;

  const connected_company_id = auth.interface._company._id;
  const company = auth.interface._company;

  const company_id = contract?.contractContractor?._company?._id;

  return (
    <Box marginTop={'10px'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
      >
        {Boolean(
          !king_fisher_fake_double_validation &&
            connected_company_id === KINGFISHER_DEMO_ACCOUNT_ID &&
            is_king_fisher_investment_request
        ) ? (
          <Box>
            <Button
              onClick={() => set_king_fisher_fake_double_validation(true)}
              disabled={isLoading}
              endIcon={
                <FAIcon
                  collection={'fal'}
                  icon={'thumbs-down'}
                  className={classes.viewActionIcon}
                />
              }
              color="secondary"
              variant="contained"
              className={classNames(classes.viewAction, classes.viewActionError)}
            >
              {t("Refuser l'investissement")}
            </Button>

            <Button
              onClick={() => set_king_fisher_fake_double_validation(true)}
              disabled={isLoading}
              endIcon={
                <FAIcon
                  collection={'fal'}
                  icon={'thumbs-up'}
                  className={classes.viewActionIcon}
                />
              }
              color={'secondary'}
              variant={'contained'}
              className={classNames(classes.viewAction, classes.viewActionSuccess)}
            >
              {t("Valider l'investissement")}
            </Button>
          </Box>
        ) : (
          unvalidated_quotes.map((devis, i) => (
            <Box
              key={k(i)}
              marginTop={'16px'}
            >
              <Box
                display={'flex'}
                justifyContent={'flex-end'}
                marginBottom={'6px'}
                paddingRight={'10px'}
              >
                <strong>{`${devis.reference} / ${formatCurrency({
                  number: devis.price,
                  locale: company?.currency?.locale,
                  currency: company?.currency?.code
                })}`}</strong>
              </Box>
              <Box>
                <Button
                  startIcon={<Visibility />}
                  style={{ position: 'relative', left: 4 }}
                  onClick={() => {
                    setFilesActions(() => () => (
                      <FileActions
                        company_id={company_id}
                        actions={actions}
                        devis={devis}
                      />
                    ));
                    openFiles([devis._document]);
                  }}
                >
                  {/* @TRANSLATE */}
                  Ouvrir
                </Button>

                <Button
                  onClick={() =>
                    setReasonOpen(() => (reason) => actions.devis_validation.action(false, reason, devis._id))
                  }
                  disabled={isLoading}
                  endIcon={
                    <FAIcon
                      collection={'fal'}
                      icon={'thumbs-down'}
                      className={classes.viewActionIcon}
                    />
                  }
                  color="secondary"
                  variant="contained"
                  className={classNames(classes.viewAction, classes.viewActionError)}
                >
                  {t('decline')}
                </Button>

                <Button
                  onClick={() => actions.devis_validation.action(true, null, devis._id)}
                  disabled={isLoading}
                  endIcon={
                    <FAIcon
                      collection={'fal'}
                      icon={'thumbs-up'}
                      className={classes.viewActionIcon}
                    />
                  }
                  color={'secondary'}
                  variant={'contained'}
                  className={classNames(classes.viewAction, classes.viewActionSuccess)}
                >
                  {t('acceptQuote')}
                </Button>
              </Box>
            </Box>
          ))
        )}
      </Box>
    </Box>
  );
};

export default DevisValidation;
