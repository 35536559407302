import Role from 'entities/Role/Role';

export const formatFilter = (filters) => filters;

const ManagerFilters = (configuration, { customFields }) =>
  [
    {
      label: 'role',
      key: 'roles',
      type: 'Entity',
      params: {
        entity: Role,
        entityProps: { defaultFilters: { types: 'user' } }
      },
      disabled: false,
      show: true,
      default: []
    },
    {
      label: 'field',
      key: 'fields',
      type: 'Field',
      params: {},
      show: customFields?.some((section) => !!section.fields?.length),
      disabled: false,
      default: []
    }
  ].filter((e) => e.show);

export default ManagerFilters;
