import { Box, Divider, InputBase, ButtonBase, Menu, MenuItem, InputLabel } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { countryLangage } from 'config/languages';

import clsx from 'clsx';

import parsePhoneNumber, { AsYouType } from 'libphonenumber-js';

import { useRef, useState, useEffect } from 'react';
import Flag from 'react-flagkit';
import { useField } from 'frmx';
import useKeys from '@flowsn4ke/usekeys';

import countries from 'constants/countries';

import { useForm } from 'frmx';
import { useAuth } from 'hooks/useAuth';

const useStyles = makeStyles((theme) => ({
  root: ({ isFocused, isHovered, isError, variant }) => ({
    display: 'flex',
    width: '100%',
    ...(variant === 'outlined'
      ? {
          height: 54,
          alignItems: 'center',
          borderRadius: 8,
          borderStyle: 'solid'
        }
      : {
          height: 30,
          borderBottom: '1px solid'
        }),
    borderWidth: isFocused ? 2 : 1,
    borderColor: isError
      ? theme.palette.error.main
      : isFocused
      ? theme.palette.primary.main
      : isHovered && variant === 'outlined'
      ? 'rgba(0, 0, 0, 0.87)'
      : variant === 'outlined'
      ? 'rgba(0, 0, 0, 0.23)'
      : 'rgba(0, 0, 0, 0.87)'
  }),
  input: ({ variant }) => ({
    paddingLeft: variant === 'outlined' ? '1em' : 0,
    paddingRight: 0,
    width: '100%'
  }),
  countrySelect: ({ variant }) => ({
    ...(variant === 'outlined' ? { height: '100%' } : {}),
    paddingLeft: 18,
    paddingRight: 0
  })
}));

export default function PhoneField({
  path,
  className,
  placeholder = '',
  variant = '',
  label = 'Phone',
  disabled = false,
  mandatory = false
}) {
  const { value, setValue, onBlur } = useField(path);

  const [error, setError] = useState(false);
  const auth = useAuth();
  const lang = auth.interface._company.lang;

  const [country, setCountry] = useState(lang ? countryLangage[lang] : 'FR');

  const [isFocused, setIsFocused] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { setOneError } = useForm();

  const { t } = useTranslation();

  useEffect(() => {
    const phone_number = parsePhoneNumber(value || '');

    if (!phone_number) return;

    if (phone_number.isValid()) {
      setCountry(phone_number.country);
    } else {
      setError(true);
      setOneError(path, true);
    }
  }, []);

  useEffect(() => {
    if (mandatory && !value) setError(true) && setOneError(path, true);
    else setOneError(path, false) && setError(false);
  }, [mandatory]);

  const handleChange = useRef((e) => {
    const phone_number = parsePhoneNumber(e.target.value, country);
    setValue(e.target.value);

    if (phone_number && !phone_number.isValid()) {
      setError(true);
      setOneError(path, true);
    } else if (phone_number) {
      setError(false);
      setOneError(path, false);
      setValue(phone_number.number);
      setCountry(phone_number.country);
    } else if (e.target.value === '') {
      setError(false);
      setOneError(path, false);
    } else {
      setError(true);
      setOneError(path, true);
    }
  });
  const closeMenu = useRef(() => setAnchorEl(null));

  const k = useKeys();
  const classes = useStyles({ isFocused, isHovered, isError: error, variant });
  return (
    <>
      {variant !== 'outlined' && <InputLabel shrink>{label}</InputLabel>}
      <Box className={clsx(classes.root, className)}>
        <ButtonBase
          className={classes.countrySelect}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          disabled={disabled}
        >
          <Flag country={country} />
          <ArrowDropDownIcon className={classes.dropDownIcon} />
        </ButtonBase>
        {variant === 'outlined' && <Divider orientation="vertical" />}
        <Box className={classes.input}>
          <InputBase
            type="tel"
            error={error}
            disabled={disabled}
            // value={value}
            value={!!value ? new AsYouType(country).input(value) : ''}
            onChange={handleChange.current}
            onFocus={() => setIsFocused(true)}
            onBlur={() => [setIsFocused(false), onBlur()]}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={classes.input}
            placeholder={placeholder}
          />
        </Box>
      </Box>
      {error && <div className="text-red-500 bold text-xs mt-1 ml-4">{t('InvalidPhoneNumber')}</div>}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={closeMenu.current}
      >
        {countries.map((c, i) => (
          <MenuItem
            key={k(i)}
            onClick={() => {
              setCountry(c.code);
              setValue(c.prefix);
              closeMenu.current();
            }}
          >
            {c.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
