import useKeys from '@flowsn4ke/usekeys';
import { makeStyles, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';
import Supplier from 'entities/Suppliers/Supplier';
import Ticket from 'entities/Ticket/Ticket';
import { dateToLocalFormat } from 'utils/dates';
import { linkEquipmentsToStock, updateElement } from 'store/stocksSlice';
import EntityTabPicker from 'components/inputs/EntityTabPicker';
import Manager from 'entities/Manager/Manager';
import Location from 'entities/Location/Location';
import Equipment from 'entities/Equipment/Equipment';

const StockTabs = (config, element) =>
  [
    {
      label: 'Consommations',
      icon: 'box-ballot',
      count: element?._consumptions?.length || 0,
      show: element?._consumptions?.length,
      render: () => (
        <Ticket
          disableGutters
          hiddenFilters={{
            _consumptions: element?._consumptions
          }}
          defaultFilters={{
            section: { closed: true }
          }}
        />
      )
    },
    {
      label: 'Historique',
      icon: 'clock-rotate-left',
      count: element?.history?.length,
      show: true,
      render: () => <History history={element?.history} />
    },
    {
      label: 'Fournisseurs',
      icon: 'truck',
      count: element?._suppliers?.length,
      show: true,
      render: () => (
        <EntityTabPicker
          Entity={Supplier}
          initialValues={{ _suppliers: element?._suppliers || [] }}
          fieldPath="_suppliers"
          elementId={element?._id}
          action={updateElement}
        />
      )
    },
    {
      label: 'Gestionnaires',
      icon: 'user-headset',
      count: element?._managers?.length,
      show: true,
      render: () => (
        <EntityTabPicker
          Entity={Manager}
          initialValues={{ _managers: element?._managers || [] }}
          fieldPath="_managers"
          elementId={element?._id}
          action={updateElement}
        />
      )
    },
    {
      label: 'Lieux',
      icon: 'map-marker-alt',
      count: element?._locations?.length,
      show: true,
      render: () => (
        <EntityTabPicker
          Entity={Location}
          initialValues={{ _locations: element?._locations || [] }}
          fieldPath="_locations"
          elementId={element?._id}
          action={updateElement}
        />
      )
    },
    {
      label: 'Equipements',
      icon: 'wrench-simple',
      count: element?._equipments?.length,
      show: true,
      render: () => (
        <EntityTabPicker
          Entity={Equipment}
          initialValues={{ _equipments: element?._equipments || [] }}
          fieldPath="_equipments"
          elementId={element?._id}
          action={linkEquipmentsToStock}
        />
      )
    }
  ].filter((e) => e.show);

export default StockTabs;

const useStyles = makeStyles((theme) => ({
  badgeSuccess: {
    ['& > span']: {
      backgroundColor: theme.palette.success.dark,
      margin: 0,
      padding: '1px',
      fontSize: '9px'
    }
  },
  list: {
    backgroundColor: 'white',
    minHeight: '100%',
    paddingTop: 0,
    ['& > :nth-child(even)']: {
      background: '#ebebeb'
    }
  }
}));

const entryTypes = {
  order: ({ entry }) => {
    return (
      <>
        <ListItemIcon>
          <FAIcon
            collection="fal"
            icon="box"
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <span>
              {entry._user?.lastName + ' ' + entry._user?.firstName}
              <strong>{' a commandé ' + entry?.data?.quantity + ' unités'}</strong>
              {' auprès du fournisseur ' + entry.data._supplier.companyName}
              <strong>{entry.data.reference && <> #{entry.data.reference}</>}</strong>
            </span>
          }
          secondary={'le ' + dateToLocalFormat(entry.at, 'PPPp')}
        />
      </>
    );
  },
  adjustement: ({ entry }) => {
    let adjustment = entry?.data?.oldStock - entry?.data?.newStock;
    adjustment = adjustment < 0 ? `+${Math.abs(adjustment)}` : `-${adjustment}`;
    return (
      <>
        `{' '}
        <ListItemIcon>
          <FAIcon
            collection="fal"
            icon="clipboard-list"
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <span>
              {entry._user?.lastName + ' ' + entry._user?.firstName}
              <strong>{' a procédé à un ajustement de stock (' + adjustment + ' unités)'}</strong>
            </span>
          }
          secondary={'le ' + dateToLocalFormat(entry.at, 'PPPp')}
        />
        `
      </>
    );
  },
  'order-received': ({ entry }) => {
    return (
      <>
        <ListItemIcon>
          <FAIcon
            collection="fal"
            icon="box-circle-check"
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <span>
              {entry._user?.lastName + ' ' + entry._user?.firstName}
              <strong>
                {'a réceptionné la commande'}
                {entry?.data?.reference && <> #{entry?.data?.reference}</>}
              </strong>
              {` (${entry?.data?.quantity} unités)`}
            </span>
          }
          secondary={'le ' + dateToLocalFormat(entry.at, 'PPPp')}
        />
      </>
    );
  },
  'order-canceled': ({ entry }) => {
    return (
      <>
        <ListItemIcon>
          <FAIcon
            collection="fal"
            icon="ban"
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <span>
              {entry._user?.lastName + ' ' + entry._user?.firstName}{' '}
              <strong>{' a annulé la commande #' + entry?.data?.reference}</strong>
            </span>
          }
          secondary={'le ' + dateToLocalFormat(entry.at, 'PPPp')}
        />
      </>
    );
  },
  'order-stock-reached': ({ entry }) => {
    return (
      <>
        <ListItemIcon>
          <FAIcon
            collection="fal"
            icon="triangle-exclamation"
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <span>
              <strong>Le seuil d'alerte a été atteint</strong>
            </span>
          }
          secondary={'le ' + dateToLocalFormat(entry.at, 'PPPp')}
        />
      </>
    );
  }
};

function History({ history }) {
  const classes = useStyles();
  const k = useKeys();

  return (
    <List className={classes.list}>
      {history.map((entry, i) => {
        const Component = entryTypes[entry.type];
        return (
          <ListItem key={k(i)}>
            <Component entry={entry} />
          </ListItem>
        );
      })}
    </List>
  );
}
