import { Box, Chip, Divider, Button, Tooltip, Typography, IconButton, useTheme, Popover } from '@material-ui/core';

import { Add, RadioButtonChecked, AccessTime, Email, Phone } from '@material-ui/icons';

import { dateToLocalFormat } from 'utils/dates';
import { useAuth } from 'hooks/useAuth';
import { useContract } from 'contexts/entities/contractContext';
import { useRole } from 'hooks/useRole';
import { useRef, useState } from 'react';
import PopoverDrawer from 'components/ui/PopoverDrawer';
import useStyles from 'layouts/entities/View.styles';

import Job from 'entities/Job/Job';
import Manager from 'entities/Manager/Manager';
import useKeys from '@flowsn4ke/usekeys';

import { contractStates } from 'lists/contractStates';

import { Form, useForm } from 'frmx';
import UserChip from 'components/Interface/UserChip';
import { putDelays, updateElement, updateManagers } from 'store/ticketsSlice';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import useNotifications from 'hooks/useNotifications';
import formatPhoneNumber from 'utils/formatPhoneNumber';
import { useConfiguration } from 'hooks/useConfiguration';
import DelayForm from '../Forms/DelayForm';
import { KINGFISHER_DEMO_ACCOUNT_TICKET_DOMAIN_INVESTISSEMENT_ID } from 'index';
import { useEntity } from 'contexts/entities/entityContext';
import { formatCurrency } from 'utils/formatCurrency';

export default function TicketHeader() {
  const { order, contract, contractParent, isProposition, dispatch } = useContract();
  const { t } = useTranslation();

  const auth = useAuth();
  const company = auth.interface._company;
  const role = useRole();
  const config = useConfiguration();
  const [managersPopover, setManagersPopover] = useState(false);
  const [jobsPopover, setJobsPopover] = useState(false);
  const [delay_popover, set_delay_popover] = useState(false);
  const [type_of, set_type_of] = useState('');

  const ref_delay_popover = useRef(null);

  const classes = useStyles();
  const theme = useTheme();
  const k1 = useKeys();
  const k2 = useKeys();
  const notify = useNotifications();
  const { element } = useEntity();

  const category = element.category;
  const is_king_fisher_investment_request = category === KINGFISHER_DEMO_ACCOUNT_TICKET_DOMAIN_INVESTISSEMENT_ID;

  const urgencies = order.creatorConfiguration?.urgencies;
  const urgency =
    !order.period &&
    urgencies &&
    !!urgencies[order.urgency] &&
    !!urgencies[order.urgency].label?.length &&
    urgencies[order.urgency];

  const stateObject = contractStates[contract.state];

  const deadlineExceed = !!contract.deadline && Date.now() > +new Date(contract.deadline.date);

  const threshold =
    !!contractParent && contractParent?.seuil_enable
      ? contractParent.seuil
      : contract?.seuil_enable
      ? contract.seuil
      : null;

  const has_threshold = Boolean(contract?.seuil_enable);

  const dispatchCB = {
    success: () => notify.success(),
    error: () => notify.error()
  };

  const userIsAssigned = !!contract._managers?.find((m) => m?._id === auth.user?._id);

  const user_has_permission_to_define_delays = role.permission('tickets', 'define_intervention_or_resolution_delay');

  const handle_click_button_delay = (event, type_of_delay) => {
    set_delay_popover((prev) => !prev);
    set_type_of(type_of_delay);
    ref_delay_popover.current = event.currentTarget;
  };

  const convert_delay_to_hours = (delay) => {
    if (!delay) return 'N/A';

    return delay / 3600000 + 'H';
  };

  const order_has_finished_or_closed = order?.contract?.state === 'finished' || order?.contract?.state === 'closed';

  const is_company_creator = order.companyCreator?._id === auth.interface._company._id;

  return (
    <Box className={classes.l1}>
      <Box
        alignItems="center"
        display="flex"
        flexGrow={1}
        width="100%"
      >
        <Box
          flexDirection="column"
          alignItems="flex-start"
          flexGrow={1}
          width="100%"
        >
          <Box className={classes.l1left}>
            {
              <span className={classes.l1reference}>
                #{order.number}
                <span style={{ color: stateObject.color, marginLeft: 6 }}>{t(stateObject.label)}</span>
                {contract.intervention_compute && (
                  <Tooltip
                    placement={'right'}
                    title={t('intervenerOnTheSpot')}
                  >
                    <Box marginLeft="6px">
                      <FAIcon
                        style={{ color: '#f1c40f' }}
                        collection="fad"
                        icon="street-view"
                      />
                    </Box>
                  </Tooltip>
                )}
                {!!contract.deadline && (
                  <Tooltip
                    placement={'right'}
                    title={
                      <>
                        {dateToLocalFormat(contract.deadline.date)}
                        {
                          <>
                            <br />
                            {contract.deadline.description}
                          </>
                        }
                      </>
                    }
                  >
                    <AccessTime
                      style={{
                        marginLeft: 6,
                        color: deadlineExceed ? theme.palette.primary.dark : theme.palette.secondary.light
                      }}
                    />
                  </Tooltip>
                )}
              </span>
            }
            <div className="sm:flex mb-1">
              {/* INTERVENTION DELAY */}
              {(config?.follow_up_intervention_delay || order?.intervention_deadline || order?.intervention_delay) && (
                <div className="mb-2 sm:mr-2 xl:absolute top-[7px] right-52">
                  <Tooltip
                    title={order?.intervention_deadline && dateToLocalFormat(order?.intervention_deadline, 'PPPp')}
                    disableHoverListener={order?.contract?.intervention_dates?.length === 0}
                  >
                    <span>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={(event) => handle_click_button_delay(event, 'Intervention')}
                        disabled={
                          !config?.follow_up_intervention_delay ||
                          !user_has_permission_to_define_delays ||
                          order?.contract?.intervention_dates?.length > 0 ||
                          !is_company_creator
                        }
                      >
                        {order?.intervention_deadline || order?.intervention_delay ? (
                          <>
                            <div className="flex items-center">
                              <FAIcon
                                icon="wrench"
                                collection="fas"
                                size="small"
                                style={{
                                  color: order?.intervention_after_deadline
                                    ? '#e63d53'
                                    : !order?.intervention_after_deadline &&
                                      order?.contract?.intervention_dates?.length === 0
                                    ? ''
                                    : '#31b55a'
                                }}
                              />
                              {order?.intervention_deadline
                                ? dateToLocalFormat(order?.intervention_deadline, 'P')
                                : convert_delay_to_hours(order?.intervention_delay)}
                            </div>
                          </>
                        ) : (
                          <div className="flex items-center">
                            <span>{t('followUpIntervention')}</span>
                          </div>
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              )}

              {/* RESOLUTION DELAY */}
              {(config?.follow_up_resolution_delay || order?.resolution_deadline || order?.resolution_delay) && (
                <div className="xl:absolute top-[7px] right-16">
                  <Tooltip
                    title={order?.resolution_deadline && dateToLocalFormat(order?.resolution_deadline, 'PPPp')}
                    disableHoverListener={!order_has_finished_or_closed}
                  >
                    <span>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={(event) => handle_click_button_delay(event, 'Resolution')}
                        disabled={
                          !config?.follow_up_resolution_delay ||
                          !user_has_permission_to_define_delays ||
                          order_has_finished_or_closed ||
                          !is_company_creator
                        }
                      >
                        {order?.resolution_deadline || order?.resolution_delay ? (
                          <>
                            <div className="flex items-center">
                              <FAIcon
                                icon="check"
                                collection="fas"
                                size="small"
                                style={{
                                  color: order?.resolution_after_deadline
                                    ? '#e63d53'
                                    : !order?.resolution_after_deadline && !order_has_finished_or_closed
                                    ? ''
                                    : '#31b55a'
                                }}
                              />
                              {order?.resolution_deadline
                                ? dateToLocalFormat(order?.resolution_deadline, 'P')
                                : convert_delay_to_hours(order?.resolution_delay)}
                            </div>
                          </>
                        ) : (
                          <div className="flex items-center">
                            <span>{t('followUpResolution')}</span>
                          </div>
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                </div>
              )}
            </div>
            {user_has_permission_to_define_delays && (
              <Form
                refresh
                disableIfNoUpdates
                initialValues={{
                  intervention_delay: order?.intervention_delay,
                  intervention_deadline: order?.intervention_deadline, // timestamp in ms
                  resolution_delay: order?.resolution_delay,
                  resolution_deadline: order?.resolution_deadline, // timestamp in ms
                  type_of_delay: String(type_of).toLowerCase(),
                  is_created_delay: true
                }}
                onSubmit={(data) => dispatch(putDelays, data, {}, { id: order._id })}
              >
                <DelayPopoverWrapper
                  delay_popover={delay_popover}
                  set_delay_popover={set_delay_popover}
                  ref_delay_popover={ref_delay_popover}
                  type_of={type_of}
                  set_type_of={set_type_of}
                  ticket={order}
                />
              </Form>
            )}
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              {!order.period && order.title}
              {has_threshold && (
                <Tooltip
                  placement="right"
                  title={t('priceToInterveneWithoutQuote')}
                >
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        marginLeft: '1em',
                        display: 'flex',
                        alignItems: 'end',
                        fontSize: 13
                      }}
                      color="primary"
                    >
                      {threshold > 0 ? (
                        <>
                          {formatCurrency({
                            number: threshold,
                            locale: company?.currency?.locale,
                            currency: company?.currency?.code
                          })}
                          <sup style={{ fontSize: '.7em' }}>HT</sup>
                        </>
                      ) : (
                        <p
                          style={{
                            color: threshold === 0 ? '#ef4444' : theme.palette.primary.main,
                            marginLeft: '.5em'
                          }}
                        >
                          Devis obligatoire
                        </p>
                      )}
                    </Typography>
                    <FAIcon
                      collection="fad"
                      icon="truck-bolt"
                      size="small"
                      style={{
                        color: threshold === 0 ? '#ef4444' : theme.palette.primary.main,
                        marginLeft: '.5em'
                      }}
                    />
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Box>

          {!order.period && (
            <Box
              fontStyle="italic"
              fontWeight={500}
              fontSize={10}
              color="#a9afb5"
              top={2}
              position="relative"
            >
              <span style={{ marginRight: 4 }}>
                {t('createdOnX', {
                  date: dateToLocalFormat(order.created_at, 'PPPPp')
                })}
              </span>
              {!!order._user &&
                !order.creator &&
                t('by', {
                  by: order._user.firstName + ' ' + order._user.lastName
                })}
              {!!order.creator && t('by', { by: order.creator })}
              <br />
              {!!order?.creator_email && (
                <>
                  {order?.creator_email}&nbsp;&nbsp;
                  <IconButton
                    size={'small'}
                    onClick={() => window.open(`mailto: ${order?.creator_email}`, '_self')}
                  >
                    <Email style={{ fontSize: 13, width: 13, height: 13 }} />
                  </IconButton>
                </>
              )}
              <br />
              {!!order?.creator_phone && (
                <>
                  {formatPhoneNumber(order.creator_phone)}
                  <IconButton
                    style={{ marginLeft: 6 }}
                    size={'small'}
                    onClick={() => window.open(`tel: ${order.creator_phone}`, '_self')}
                  >
                    <Phone style={{ fontSize: 13, width: 13, height: 13 }} />
                  </IconButton>
                </>
              )}
            </Box>
          )}

          {role.permission('tickets', 'job') && (
            <Form
              refresh
              disableIfNoUpdates
              initialValues={{
                _jobs: order._jobs
              }}
              onSubmit={(form) => [
                dispatch(updateElement, { _jobs: form._jobs }, {}, { id: order._id }),
                setJobsPopover(false)
              ]}
            >
              <JobsPopover
                jobsPopover={jobsPopover}
                setJobsPopover={setJobsPopover}
              />
            </Form>
          )}

          <Box style={{ display: 'flex', width: '100%', marginTop: '1em' }}>
            <Box
              style={{
                flexGrow: 2,
                display: 'flex',
                alignItems: 'start',
                flexWrap: 'wrap',
                height: '100%',
                width: '100%'
              }}
            >
              {urgency && (
                <Chip
                  color="secondary"
                  variant="default"
                  size="small"
                  label={t(urgency.label)}
                  style={{
                    marginRight: 6,
                    marginBottom: 6,
                    backgroundColor: urgency.color
                  }}
                />
              )}
              {is_king_fisher_investment_request && (
                <Chip
                  className="bg-red-500"
                  variant="default"
                  size="small"
                  label={t('Investissement')}
                  style={{
                    marginRight: 6,
                    marginBottom: 6,
                    backgroundColor: '#ffbb00',
                    color: '#fff'
                  }}
                />
              )}
              {order._jobs.map((job, i) => (
                <Chip
                  key={k1(i)}
                  onClick={(e) => setJobsPopover(e.currentTarget)}
                  icon={<RadioButtonChecked style={{ color: job.color }} />}
                  clickable
                  color="secondary"
                  variant="outlined"
                  size="small"
                  label={t(job.name)}
                  style={{
                    marginRight: 6,
                    marginBottom: 4,
                    borderColor: job.color
                  }}
                />
              ))}
              {role.permission('tickets', 'job') && !order._jobs?.length && (
                <>
                  <Chip
                    onClick={(e) => setJobsPopover(e.currentTarget)}
                    icon={<Add />}
                    clickable
                    color="secondary"
                    variant="outlined"
                    size="small"
                    label={t('addAJob')}
                    style={{
                      marginRight: 6,
                      marginBottom: 4
                    }}
                  />
                </>
              )}
            </Box>
            <Box
              style={{
                flexGrow: 3,
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                justifyContent: 'center'
              }}
            >
              {!isProposition && !!contract._managers && (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                  >
                    {contract._managers.map((manager, i) => (
                      <UserChip
                        key={k2(i)}
                        user={manager}
                        type={auth.interface.type}
                        style={{ marginRight: 6 }}
                      />
                    ))}
                  </Box>
                  {auth.interface.isManager && role.permission('tickets', 'managers') && (
                    <Box style={{ position: 'relative' }}>
                      <Tooltip
                        placement="left"
                        title={t('managers')}
                      >
                        <IconButton
                          size="small"
                          onClick={(e) => setManagersPopover(e.currentTarget)}
                          style={{ padding: 0, marginRight: 3 }}
                        >
                          <FAIcon
                            icon="tags"
                            collection="fal"
                            style={{ color: '#a9afb5' }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Form
                        refresh
                        disableIfNoUpdates
                        initialValues={{ _managers: contract._managers }}
                        onSubmit={(form) => [
                          dispatch(updateManagers, { _managers: form._managers }, {}, { id: order._id }),
                          setManagersPopover(false)
                        ]}
                      >
                        <ManagerPopoverWrapper
                          managersPopover={managersPopover}
                          setManagersPopover={setManagersPopover}
                        />
                      </Form>

                      <Tooltip
                        placement="left"
                        title={t(userIsAssigned ? 'detachMe' : 'attachMe')}
                      >
                        <IconButton
                          style={{
                            padding: 0,
                            top: 24,
                            right: 7,
                            position: 'absolute'
                          }}
                          onClick={() => {
                            dispatch(
                              updateManagers,
                              {
                                _managers: userIsAssigned
                                  ? contract._managers.filter((m) => m._id !== auth.user._id)
                                  : [...contract._managers, auth.user]
                              },
                              dispatchCB,
                              { id: order._id }
                            );
                          }}
                        >
                          <FAIcon
                            icon={userIsAssigned ? 'circle-minus' : 'circle-plus'}
                            collection="fal"
                            size="xs"
                            style={{
                              color: userIsAssigned ? '#EE7572' : '#31B55A'
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function DelayPopoverWrapper({ delay_popover, set_delay_popover, ref_delay_popover, type_of, set_type_of, ticket }) {
  const handle_close_delay_popover = () => {
    set_type_of('');
    set_delay_popover((prev) => !prev);
  };

  return (
    <Popover
      transitionDuration={0}
      open={delay_popover}
      onClose={handle_close_delay_popover}
      anchorEl={ref_delay_popover.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        horizontal: 'center'
      }}
    >
      <DelayForm
        type_of={type_of}
        ticket_created_at={ticket.created_at}
        ticket_id={ticket._id}
        handle_close_delay_popover={handle_close_delay_popover}
      />
    </Popover>
  );
}

function ManagerPopoverWrapper({ managersPopover, setManagersPopover }) {
  const { handleSubmit } = useForm();
  return (
    <PopoverDrawer
      style={{ width: 500, height: 300 }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      anchorEl={managersPopover}
      open={!!managersPopover}
      transitionDuration={0}
      onClose={() => [setManagersPopover(false), handleSubmit()]}
    >
      <ManagersPopover />
    </PopoverDrawer>
  );
}

export function JobsPopover({ jobsPopover, setJobsPopover }) {
  const { handleSubmit } = useForm();
  const { t } = useTranslation();

  return (
    <PopoverDrawer
      style={{ width: 500, height: 300 }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      anchorEl={jobsPopover}
      open={!!jobsPopover}
      transitionDuration={0}
      onClose={(e) => [handleSubmit(e), setJobsPopover(false)]}
    >
      <Box
        style={{ width: '100%' }}
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <Box flexGrow={1}>
          <Job
            disableShadow
            picker
            pickerField="_jobs"
          />
        </Box>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          padding="4px 6px"
        >
          <Button
            onClick={(e) => [handleSubmit(e)]}
            color="primary"
            size="small"
          >
            {t('validate')}
          </Button>
        </Box>
      </Box>
    </PopoverDrawer>
  );
}

function ManagersPopover() {
  const { user } = useAuth();
  const { contract } = useContract();
  const { handleSubmit, setOneField } = useForm();
  const { t } = useTranslation();

  const dettach = () => [
    setOneField(
      '_managers',
      contract._managers.filter((manager) => manager._id !== user._id)
    ),
    handleSubmit()
  ];
  const attach = () => [setOneField('_managers', [...contract._managers, user]), handleSubmit()];

  return (
    <Box
      style={{ width: '100%' }}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <Box flexGrow={1}>
        <Manager
          disableShadow
          picker
          pickerField="_managers"
        />
      </Box>
      <Divider />
      <Box
        display="flex"
        justifyContent="space-between"
        padding="4px 6px"
      >
        {!!contract._managers.find((manager) => manager._id === user._id) && (
          <Button
            onClick={dettach}
            color="primary"
            size="small"
          >
            {t('detachMe')}
          </Button>
        )}
        {!contract._managers.find((manager) => manager._id === user._id) && (
          <Button
            onClick={attach}
            color="primary"
            size="small"
          >
            {t('attachMe')}
          </Button>
        )}
        <Button
          onClick={(e) => [handleSubmit(e)]}
          color="primary"
          size="small"
        >
          {t('validate')}
        </Button>
      </Box>
    </Box>
  );
}
