import {
  TextField,
  Checkbox,
  Divider,
  AccordionActions,
  AccordionDetails,
  Grid,
  FormControlLabel,
  FormHelperText,
  Tabs,
  Tab,
  Button,
  Box,
  Slider,
  Typography,
  IconButton,
  makeStyles
} from '@material-ui/core';

import { useState } from 'react';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { setConfiguration } from 'store/authSlice';
import { Submit, Field, Reset, useFieldObserver, useField } from 'frmx';

import AccordionForm from 'components/forms/AccordionForm';

import useStyles from './TicketConfiguration.styles';
import EntityX from 'components/inputs/EntityX';
import Scope from 'entities/Scope/Scope';
import useKeys from '@flowsn4ke/usekeys';
import DisplayMore from 'components/DisplayMore';
import Job from 'entities/Job/Job';
import { useConfiguration } from 'hooks/useConfiguration';
import FAIcon from 'components/ui/FAIcon';
import { useTranslation } from 'react-i18next';
import Manager from 'entities/Manager/Manager';
import classNames from 'utils/classNames';
import { nanoid } from 'nanoid';
import { useAuth } from 'hooks/useAuth';
import { Container, Draggable } from 'react-smooth-dnd';
import { DragIndicator } from '@material-ui/icons';
import clsx from 'clsx';
import { KINGFISHER_DEMO_ACCOUNT_ID } from 'index';

function TabPanel(props) {
  const { children, value, index, ...rest } = props;
  return value === index ? (
    <Box
      p={3}
      {...rest}
    >
      {children}
    </Box>
  ) : null;
}

export default function TicketConfiguration({ section }) {
  const classes = useStyles();

  const config = useConfiguration();
  const urgencies = config.urgencies;

  const notify = useNotifications();
  const { dispatch, requestStatus } = useAsyncDispatch();

  const dispatchCallbacks = {
    onSuccess: () => notify.success(),
    onError: () => notify.error()
  };

  const { t } = useTranslation();

  const delayTypes = [
    { value: 'days', label: t('days') },
    { value: 'hours', label: t('hours') },
    { value: 'custom', label: t('custom') },
    { value: 'none', label: t('withoutTimeLimit') }
  ];

  const k1 = useKeys();
  const k2 = useKeys();

  const jobd = config?.job_domains?.map((d) => ({
    ...d,
    _categories: [...(d?._categories?.map((c) => ({ ...c })) || [])]
  }));

  const auth = useAuth();
  const company_id = auth?.interface?._company?._id;

  return (
    <AccordionForm
      refresh
      defaultClose
      section={section}
      onSubmit={(updates) => dispatch(setConfiguration, updates, dispatchCallbacks)}
      initialValues={{
        _tva: config?._tva,
        default_intervention_date_duration: config?.default_intervention_date_duration || 60,
        job_domains: jobd || [],
        ticket_location_mandatory: config?.ticket_location_mandatory || false,
        ticket_job_toassign: config?.ticket_job_toassign || false,
        hide_job: config?.hide_job || false,
        default_job: config?.default_job || null,
        default_seuil: config?.default_seuil || 0,
        default_seuil_enable: config?.default_seuil_enable || false,
        job_mandatory: config?.job_mandatory || false,
        ticket_title_mandatory: config?.ticket_title_mandatory ?? false,
        ticket_description_mandatory: config?.ticket_description_mandatory ?? false,
        ticket_criticity_mandatory: config?.ticket_criticity_mandatory ?? false,
        ticket_contacts_autoselection: config?.ticket_contacts_autoselection ?? false,
        assign_sms: config?.assign_sms || false,
        assign_email: config?.assign_email || false,
        ticket_ir_mandatory: config?.ticket_ir_mandatory || false,
        replan_reasons: config?.replan_reasons || [],
        ticket_domain_mandatory: config?.ticket_domain_mandatory || false,
        ticket_location_unique: config?.ticket_location_unique || false,
        ticket_unique_intervention_date: config?.ticket_unique_intervention_date || false,
        auto_transmission: {
          date_client_to_contractor: config?.auto_transmission?.date_client_to_contractor || false,
          date_contractor_to_client: config?.auto_transmission?.date_contractor_to_client || false,
          date_me_to_contractor: config?.auto_transmission?.date_me_to_contractor || false,
          date_me_to_client: config?.auto_transmission?.date_me_to_client || false,
          document_me_to_client: config?.auto_transmission?.document_me_to_client || false,
          document_me_to_contractor: config?.auto_transmission?.document_me_to_contractor || false,
          document_client_to_contractor:
            config?.auto_transmission?.document_client_to_contractor || false,
          document_contractor_to_client:
            config?.auto_transmission?.document_contractor_to_client || false,
          facture_contractor_to_client:
            config?.auto_transmission?.facture_contractor_to_client || false,
          devis_contractor_to_client: config?.auto_transmission?.devis_contractor_to_client || false
        },
        urgencies: {
          1: {
            color: urgencies['1']?.color || '#000000',
            delay: urgencies['1']?.delay || 0,
            delayType: urgencies['1']?.delayType || '',
            label: urgencies['1']?.label || '',
            roles: []
          },
          2: {
            color: urgencies['2']?.color || '#000000',
            delay: urgencies['2']?.delay || 0,
            delayType: urgencies['2']?.delayType || '',
            label: urgencies['2']?.label || '',
            roles: []
          },
          3: {
            color: urgencies['3']?.color || '#000000',
            delay: urgencies['3']?.delay || 0,
            delayType: urgencies['3']?.delayType || '',
            label: urgencies['3']?.label || '',
            roles: []
          },
          4: {
            color: urgencies['4']?.color || '#000000',
            delay: urgencies['4']?.delay || 0,
            delayType: urgencies['4']?.delayType || '',
            label: urgencies['4']?.label || '',
            roles: []
          }
        },
        follow_up_intervention_delay: config?.follow_up_intervention_delay || false,
        follow_up_resolution_delay: config?.follow_up_resolution_delay || false,
        quotes_price_not_null: config?.quotes_price_not_null || false,
        limit_actions_when_mandatory_quote: config?.limit_actions_when_mandatory_quote || false
      }}
    >
      <AccordionDetails>
        <Grid container>
          <Grid
            item
            xs={12}
          >
            <DisplayMore label={t('ticketCreation')}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_title_mandatory"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('mandatoryTitle')}
                  />
                  <FormHelperText>{t('mandatoryTitleHelperText')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_description_mandatory"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('mandatoryDescription')}
                  />
                  <FormHelperText>{t('mandatoryDescriptionHelperText')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="hide_job"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('hideJobInModal')}
                  />
                  <FormHelperText>{t('madatoryJobHelperText')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="job_mandatory"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('madatoryJob')}
                  />
                  <FormHelperText>{t('madatoryJobHelperText2')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <EntityX
                    path="default_job"
                    entity={Job}
                    placeholder={t('defaultJob')}
                    pickerUniq
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_location_mandatory"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('mandatoryLocation')}
                  />
                  <FormHelperText>{t('mandatoryLocationHelperText')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_job_toassign"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label="Métier obligatoire pour assigner"
                  />
                  <FormHelperText>{t('noIntervenerOnTicketWithoutJob')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_location_unique"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('uniqueLocation')}
                  />
                  <FormHelperText>{t('uniqueLocationHelperText')}</FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_contacts_autoselection"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('autoSelectUsers')}
                  />
                  <FormHelperText>{t('autoSelectUsersHelperText')}</FormHelperText>
                </Grid>
              </Grid>
            </DisplayMore>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <DisplayMore label={t('criticityAndDealines')}>
              <Grid
                container
                spacing={2}
              >
                <Grid item>
                  <Grid>
                    <FormControlLabel
                      control={
                        <Field
                          path="follow_up_intervention_delay"
                          type="checkbox"
                        >
                          <Checkbox />
                        </Field>
                      }
                      label={t('followUpInterventionDelay')}
                    />
                    <FormHelperText>{t('followUpInterventionDelayHelperText')}</FormHelperText>
                  </Grid>
                  <Grid>
                    <FormControlLabel
                      control={
                        <Field
                          path="follow_up_resolution_delay"
                          type="checkbox"
                        >
                          <Checkbox />
                        </Field>
                      }
                      label={t('followUpResolutionDelay')}
                    />
                    <FormHelperText>{t('followUpResolutionDelayHelperText')}</FormHelperText>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="ticket_criticity_mandatory"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('mandatoryUrgencies')}
                  />
                  <FormHelperText>{t('mandatoryUrgenciesDes')}</FormHelperText>
                </Grid>
                {Object.keys(urgencies).map((u, i) => (
                  <Grid
                    item
                    xs={12}
                    key={k2(i)}
                  >
                    <Box className={classes.urgencyField}>
                      <Field
                        path={`urgencies.${u}.color`}
                        type="color"
                      >
                        <input className={classes.colorPicker} />
                      </Field>

                      <Field path={`urgencies.${u}.label`}>
                        <TextField
                          label={t('criticity')}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          className={classes.input}
                        />
                      </Field>

                      <Field
                        path={`urgencies.${u}.delay`}
                        type="number"
                      >
                        <TextField
                          label={t('valueTitle')}
                          variant="outlined"
                          className={classNames(classes.input, classes.number)}
                        />
                      </Field>

                      <Field path={`urgencies.${u}.delayType`}>
                        <TextField
                          select
                          variant="outlined"
                          label={t('deadline')}
                          SelectProps={{ native: true }}
                          className={classNames(classes.input, classes.delayType)}
                        >
                          {delayTypes.map((option, i) => (
                            <option
                              key={k1(i)}
                              value={option.value}
                            >
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Field>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </DisplayMore>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <DisplayMore label={t('notifications')}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="assign_sms"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('preselectSMS')}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Field
                        path="assign_email"
                        type="checkbox"
                      >
                        <Checkbox />
                      </Field>
                    }
                    label={t('preselectEmail')}
                  />
                </Grid>
              </Grid>
            </DisplayMore>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <DisplayMore label={t('autoTransmission')}>
              <AutomaticTransmissions />
            </DisplayMore>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <DisplayMore label={t('domainConfiguration')}>
              <JobDomains />
            </DisplayMore>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <DisplayMore label={t('other')}>
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                >
                  <EntityX
                    path="_tva"
                    entity={Scope}
                    entityProps={{ type: 'ticketTva' }}
                    placeholder={t('vatRate')}
                    pickerUniq
                  />
                </Grid>

                <InterventionDuration />

                {config.feature?.seuil && <DefaultThreshold />}
              </Grid>
            </DisplayMore>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="ticket_ir_mandatory"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('mandatoryIR')}
            />
            <FormHelperText>
              {t(
                config.isBlachere
                  ? 'blachCloseInterventionAfterEnded'
                  : 'closeInterventionAfterEnded'
              )}
            </FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="ticket_unique_intervention_date"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('uniqueInterventionDate')}
            />
            <FormHelperText>{t('ticketHaveOneInterventionDate')}</FormHelperText>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <FormControlLabel
              control={
                <Field
                  path="quotes_price_not_null"
                  type="checkbox"
                >
                  <Checkbox />
                </Field>
              }
              label={t('quotes-price-not-null')}
            />
            <FormHelperText>{t('ticket-have-quotes-price-not-null')}</FormHelperText>
          </Grid>

          {KINGFISHER_DEMO_ACCOUNT_ID === company_id && (
            <Grid
              item
              xs={12}
            >
              <FormControlLabel
                control={
                  <Field
                    path="limit_actions_when_mandatory_quote"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('Limiter les actions lorsque le devis est obligatoire')}
              />
              <FormHelperText>
                {t(
                  "Lorsque le devis est obligatoire, bloquer l'entrée d'une date d'intervention, d'une facture ou d'un rapport."
                )}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>

      <Divider />

      <AccordionActions>
        <Reset>
          <Button size="small">{t('cancel')}</Button>
        </Reset>

        <Submit disabled={requestStatus === 'loading'}>
          <Button
            size="small"
            color="primary"
          >
            {t('save')}
          </Button>
        </Submit>
      </AccordionActions>
    </AccordionForm>
  );
}

function AutomaticTransmissions() {
  const [tab, setTab] = useState(0);
  const handleChange = (_, newValue) => setTab(newValue);

  const auth = useAuth();
  const is_not_client_interface = !auth?.interface?.isClient;

  const { t } = useTranslation();

  return (
    <Box
      display={'flex'}
      width="100%"
      flexDirection={'column'}
    >
      <Box>
        {is_not_client_interface ? (
          <>
            <Tabs
              value={tab}
              style={{
                display: 'flex',
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: 8
              }}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
            >
              <Tab
                style={{
                  width: '25%',
                  borderRight: '1px solid rgba(0, 0, 0, 0.23)'
                }}
                label={t('dates')}
              />
              <Tab
                style={{
                  width: '25%',
                  borderRight: '1px solid rgba(0, 0, 0, 0.23)'
                }}
                label={t('docs')}
              />
              <Tab
                style={{
                  width: '25%',
                  borderRight: '1px solid rgba(0, 0, 0, 0.23)'
                }}
                label={t('invoices')}
              />
              <Tab
                style={{ width: '25%' }}
                label={t('quotes')}
              />
            </Tabs>
          </>
        ) : (
          <FormControlLabel
            control={
              <Field
                path="auto_transmission.document_me_to_contractor"
                type="checkbox"
              >
                <Checkbox />
              </Field>
            }
            label={t('autoTransmissionDocsMeToContractor')}
          />
        )}
      </Box>

      <Box>
        {is_not_client_interface && (
          <>
            <TabPanel
              value={tab}
              index={0}
            >
              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.date_client_to_contractor"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDateClientToContractor')}
              />

              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.date_contractor_to_client"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDateContractorToClient')}
              />

              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.date_me_to_contractor"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDateMeToContractor')}
              />

              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.date_me_to_client"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDateMeToClient')}
              />
            </TabPanel>

            <TabPanel
              value={tab}
              index={1}
            >
              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.document_me_to_client"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDocsMeToClient')}
              />

              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.document_me_to_contractor"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDocsMeToSubContractor')}
              />

              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.document_client_to_contractor"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDocsClientToContractor')}
              />

              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.document_contractor_to_client"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionDocsContractorToClient')}
              />
            </TabPanel>

            <TabPanel
              value={tab}
              index={2}
            >
              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.facture_contractor_to_client"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionInvoiceContractorToClient')}
              />
            </TabPanel>

            <TabPanel
              value={tab}
              index={3}
            >
              <FormControlLabel
                control={
                  <Field
                    path="auto_transmission.devis_contractor_to_client"
                    type="checkbox"
                  >
                    <Checkbox />
                  </Field>
                }
                label={t('autoTransmissionQuoteContractorToClient')}
              />
            </TabPanel>
          </>
        )}
      </Box>
    </Box>
  );
}

const useStyles_drag = makeStyles((theme) => ({
  dragHandle: {
    '&:hover': {
      cursor: 'move'
    }
  }
}));

function JobDomains() {
  const { t } = useTranslation();
  const classes = useStyles_drag();

  const path = 'job_domains';
  const { value: job_domains, setValue: set_job_domains } = useField(path);

  const handle_drop_job_domain = async (dropResult) => {
    try {
      const { removedIndex, addedIndex } = dropResult;
      if (removedIndex < 0 || addedIndex < 0) return;
      if (typeof removedIndex !== 'number' || typeof addedIndex !== 'number') return;

      const newFields = [...job_domains.slice(0, job_domains.length)];
      const [removed] = newFields.splice(removedIndex - 0, 1);
      newFields.splice(addedIndex - 0, 0, removed);
      set_job_domains(newFields);
    } catch (err) {
      console.log(err);
    }
  };

  const handle_add_job_domain = () =>
    set_job_domains([
      ...job_domains,
      {
        _id: nanoid(),
        label: '',
        _job: null,
        _categories: null,
        _managers: null
      }
    ]);

  const handle_delete_job_domain = (job_domain_id_to_delete) => {
    const job_domains_next = job_domains.filter(
      (job_domain) => String(job_domain._id) !== String(job_domain_id_to_delete)
    );
    return set_job_domains(job_domains_next);
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ marginBottom: '1em' }}
      >
        <FormControlLabel
          control={
            <Field
              path="ticket_domain_mandatory"
              type="checkbox"
            >
              <Checkbox />
            </Field>
          }
          label={t('mandatoryTicketDomainLabel')}
        />
        <FormHelperText>{t('mandatoryTicketDomainDescription')}</FormHelperText>
      </Grid>

      <div
        container
        className="w-full"
      >
        <Container
          dragHandleSelector=".column-drag-handle"
          onDrop={handle_drop_job_domain}
        >
          {job_domains.map((job_domain, job_domain_idx) => {
            return (
              <Draggable
                key={job_domain?._id || nanoid()}
                draggableId={job_domain?._id || nanoid()}
                index={job_domain_idx}
                className="p-1"
              >
                <div
                  key={job_domain?._id || nanoid()}
                  className="w-full flex items-center"
                >
                  <DragIndicator className={clsx(classes.dragHandle, 'column-drag-handle')} />
                  <div className="flex items-center w-full p-2">
                    <Field path={`${path}.${job_domain_idx}.label`}>
                      <TextField
                        style={{ marginRight: '1em' }}
                        fullWidth
                        variant="outlined"
                        label={t('domainName')}
                      />
                    </Field>

                    <EntityX
                      style={{ marginRight: '1em' }}
                      path={`${path}.${job_domain_idx}._job`}
                      entity={Job}
                      placeholder={t('job')}
                      pickerUniq
                      no_margin_style_select
                    />

                    <EntityX
                      style={{ marginRight: '1em' }}
                      path={`${path}.${job_domain_idx}._categories`}
                      entity={Scope}
                      entityProps={{ type: 'equipmentCategory' }}
                      placeholder={t('category')}
                      no_margin_style_select
                    />

                    <EntityX
                      style={{ marginRight: '1em' }}
                      path={`${path}.${job_domain_idx}._managers`}
                      entityName="managers"
                      entity={Manager}
                      placeholder={t('managers')}
                      no_margin_style_select
                    />

                    <IconButton onClick={() => handle_delete_job_domain(job_domain._id)}>
                      <FAIcon
                        collection="fal"
                        icon="trash-can"
                      />
                    </IconButton>
                  </div>
                </div>
              </Draggable>
            );
          })}
        </Container>
        <Grid
          item
          xs={12}
        >
          <Box style={{ width: 'full', textAlign: 'center', margin: '1em 0' }}>
            <Button
              endIcon={
                <FAIcon
                  collection="fal"
                  icon="circle-plus"
                />
              }
              variant="contained"
              onClick={() => handle_add_job_domain()}
            >
              {t('addDomain')}
            </Button>
          </Box>
        </Grid>
      </div>
    </Grid>
  );
}

function InterventionDuration(props) {
  const duration = useFieldObserver('default_intervention_date_duration');
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={12}
    >
      <Typography gutterBottom>
        {t('defaultInterventionDuration')}: <strong>{duration}</strong> {t('minutes')}
      </Typography>

      <Field
        path="default_intervention_date_duration"
        type="range"
        getValueFromArgs={(args) => args[1]}
      >
        <Slider
          min={0}
          max={180}
          step={1}
          valueLabelDisplay="auto"
        />
      </Field>
    </Grid>
  );
}

function DefaultThreshold() {
  const val = useFieldObserver('default_seuil');
  const enabled = useFieldObserver('default_seuil_enable');
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth?.interface?._company;

  return (
    <>
      <Grid
        item
        xs={12}
      >
        <FormControlLabel
          control={
            <Field
              path="default_seuil_enable"
              type="checkbox"
            >
              <Checkbox />
            </Field>
          }
          label={t('activateDefaultThreshold')}
        />
        <FormHelperText>{t('activateDefaultThresholdHelperText')}</FormHelperText>
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Typography gutterBottom>
          {t('defaultThresholdWithoutValidation')}: <strong>{enabled ? val : '0'}{ company?.currency?.symbol ? company?.currency?.symbol : '€'}</strong>
        </Typography>

        <Field
          path="default_seuil"
          type="range"
          getValueFromArgs={(args) => args[1]}
          disabled={!enabled}
        >
          <Slider
            min={0}
            max={2000}
            step={10}
            valueLabelDisplay="auto"
          />
        </Field>
      </Grid>
    </>
  );
}
