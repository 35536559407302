import { createSlice } from '@reduxjs/toolkit';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import generateAsyncThunk from 'utils/generateAsyncThunk';
import { formatElement } from './ticketsSlice';

export const getElements = generateAsyncThunk({ type: 'POST', endpoint: 'ir' });
export const getBookmarks = generateAsyncThunk({ type: 'POST', endpoint: 'ir/bookmarks' });
export const createReportNote = generateAsyncThunk({ type: 'POST', endpoint: 'ir/${id}/addNote' });
export const liftTheReserve = generateAsyncThunk({
  type: 'GET',
  endpoint: 'tickets/${id}/interventionReport/reserve'
});

const entityBoilerplate = generateEntityBoilerplate({
  getElements,
  getBookmarks,
  formatElement: (element) => {
    return {
      ...element,
      _ticket: null,
      ...formatElement(element._ticket),
      _id: element._id,
      ticketId: element._ticket._id,
      created_at: element.created_at,
      urgency: element.urgency,
      _preventif: element._ticket._preventif
    };
  }
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const reportsFileSlice = createSlice({
  name: 'reportsFile',
  initialState: { ...initialState },
  reducers: { ...reducers },
  extraReducers: {
    ...extraReducers,
    [liftTheReserve.fulfilled]: (state, action) => {
      const { id } = action.meta;
      state.db[id].reserveDate = new Date().toISOString();
      state.db[id].reserve = false;
    },
    [createReportNote.fulfilled]: (state, action) => {
      const { id: reportId } = action.meta;

      state.db[reportId].observations = [
        action.payload.data.element,
        ...state.db[reportId].observations
      ];
    }
  }
});

export const { flushElements, flushElement } = reportsFileSlice.actions;
export default reportsFileSlice.reducer;
