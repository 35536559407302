import Location from 'entities/Location/Location';
import Maintenance from 'entities/Maintenance/Maintenance';
import Ticket from 'entities/Ticket/Ticket';

const UserTabs = (configuration, element) =>
  !!element &&
  [
    {
      label: 'entityTicketName',
      icon: 'files',
      count: element.tabs?.tickets,
      show: true,
      render: () => (
        <Ticket
          disableGutters
          defaultFilters={{ managers: [element] }}
        />
      )
    },
    {
      label: 'maintenancesTitle',
      icon: 'heartbeat',
      count: element.tabs?.maintenances,
      show: configuration.feature.preventif,
      render: () => (
        <Maintenance
          disableGutters
          defaultFilters={{ managers: [element] }}
        />
      ),
      class: 'tabNoPadding'
    },
    {
      label: 'locations',
      icon: 'map-marker-alt',
      count: element._locations?.length || 0,
      show: true,
      render: () => (
        <Location
          disableGutters
          hiddenFilters={{ only: element._locations?.map((l) => l?._id || l) }}
        />
      )
    }
  ].filter((e) => e.show);

export default UserTabs;
