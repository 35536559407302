import { isEmail } from 'validator';
import { pick } from 'lodash-es';

const ContractorFormValues = (configuration) => ({
  companyName: '',
  firstName: '',
  lastName: '',
  siret: '',
  description: '',
  zones: [],
  zone_ids: [],
  _jobs: [],
  email: '',
  phone: '',
  fix: '',
  userInterface: true,
  sendEmail: true,
  lang: ''
});
export default ContractorFormValues;

export const formatForm = (dataFromForm, isCreate, attachments) => {
  if (!!attachments?.length) {
    dataFromForm.avatar = attachments[0].url;
  }

  return {
    ...dataFromForm,
    intervenerType: 'contractor',
    _user: dataFromForm.userInterface ? pick(dataFromForm, ['firstName', 'lastName']) : null
  };
};

export const ContractorValidation = (configuration, formData) => ({
  email: (value, data) => (!!(data.userInterface || value?.length) ? isEmail(value) : true),
  userInterface: (value, data) => (!!(value || data.email?.length) ? isEmail(data.email) : true)
});
