import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  List,
  TextField,
  useTheme
} from '@material-ui/core';
import FAIcon from 'components/ui/FAIcon';

import useStyles from 'layouts/entities/View.styles';
import CustomFields from '../CustomFields';
import ViewItem from 'components/views/ViewItem';
import { isArray } from 'lodash-es';
import useKeys from '@flowsn4ke/usekeys';
import { useState } from 'react';
import useAsyncDispatch from 'hooks/useAsyncDispatch';
import useNotifications from 'hooks/useNotifications';
import { adjustementStock, cancelOrder, receiveOrder, submitNewOrder } from 'store/stocksSlice';
import EntityX from 'components/inputs/EntityX';

import Supplier from 'entities/Suppliers/Supplier';
import { Field, Form, Submit } from 'frmx';
import { useTranslation } from 'react-i18next';
import { isObject } from 'lodash-es';
import { formatCurrency } from 'utils/formatCurrency';
import { useAuth } from 'hooks/useAuth';

export default function StockView({ element }) {
  const [openDialogAdjustementStock, setOpenDialogAdjustementStock] = useState(false);
  const [openDialogOrder, setOpenDialogOrder] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const k1 = useKeys();
  const { t } = useTranslation();
  const auth = useAuth();
  const company = auth.interface._company;

  const elements = isArray(element) ? element : [element];

  const { dispatch } = useAsyncDispatch();

  const handleReceiveOrder = (id) => {
    dispatch(receiveOrder, {}, {}, { id });
  };
  const handleCancelOrder = (id) => {
    dispatch(cancelOrder, {}, {}, { id });
  };

  return (
    <>
      {elements.map((el, i) => (
        <div key={k1(i)}>
          <Box className="flex flex-col text-base font-semibold gap-1">
            {el.referenceMaker && <p className="text-[#003366] text-xs">#{el.referenceMaker}</p>}
            <p className="font-medium">{el.name}</p>
          </Box>
          <Grid
            container
            className="mb-6"
          >
            <Grid
              item
              xs={12}
              sm={el.cover ? 6 : 12}
            >
              <List className={classes.list}>
                <CustomFields element={el}>
                  <ViewItem
                    icon="boxes-stacked"
                    primary="Stock actuel"
                    secondary={el.stock || 0}
                  />
                  <ViewItem
                    icon="triangle-exclamation"
                    primary={t('alertThreshold')}
                    secondary={el.orderStock || 0}
                  />
                  <ViewItem
                    icon="bullseye-arrow"
                    primary={t('targetStock')}
                    secondary={el.targetStock || 0}
                  />
                  <ViewItem
                    icon={company?.currency?.icon || 'euro-sign'}
                    primary={t('unitValuationProduct')}
                    secondary={formatCurrency({
                      number: el.unitValuationPrice || 0,
                      locale: company?.currency?.locale,
                      currency: company?.currency?.code
                    })}
                  />
                  <ViewItem
                    icon={company?.currency?.icon || 'euro-sign'}
                    primary={t('totalValuationPrice')}
                    secondary={formatCurrency({
                      number: el.unitValuationPrice * el.stock,
                      locale: company?.currency?.locale,
                      currency: company?.currency?.code
                    })}
                  />
                  {el._warehouse?.name && (
                    <ViewItem
                      icon="map-marker-alt"
                      primary={t('storageLocation')}
                      secondary={el._warehouse?.name + (el.locationPrecision ? ` (${el.locationPrecision})` : '')}
                    />
                  )}
                  {el.comments && (
                    <ViewItem
                      icon="comment"
                      primary={t('comments')}
                      secondary={el.comments}
                    />
                  )}
                  {el.supplierItemCode && (
                    <ViewItem
                      icon="barcode"
                      primary={t('supplierItemCode')}
                      secondary={el.supplierItemCode}
                    />
                  )}

                  {el._suppliers.length > 0 && (
                    <ViewItem
                      icon="truck"
                      primary={t('suppliers')}
                      secondary={el._suppliers
                        ?.map((s) => s.companyName + (s.reference ? ` (${s.reference})` : ''))
                        .join(', ')}
                    />
                  )}
                </CustomFields>
              </List>
            </Grid>
            {el.cover && (
              <Grid
                item
                xs={12}
                sm={6}
              >
                <img
                  className="shadow rounded-lg ml-auto mr-4"
                  src={el.cover}
                  alt={t('coverPicture')}
                />
              </Grid>
            )}
          </Grid>
          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              startIcon={
                <FAIcon
                  icon="clipboard-list"
                  collection="fal"
                />
              }
              variant="contained"
              color="primary"
              onClick={() => setOpenDialogAdjustementStock((prev) => !prev)}
              style={{ marginRight: 14, display: !el.pendingOrder ? '' : 'none' }}
            >
              {t('adjustmentStock')}
            </Button>
            <Button
              startIcon={
                <FAIcon
                  icon="box"
                  collection="fal"
                />
              }
              variant="contained"
              color="primary"
              onClick={() => setOpenDialogOrder((prev) => !prev)}
              style={{ display: !el.pendingOrder ? '' : 'none' }}
            >
              {t('makeOrderStock')}
            </Button>

            <Button
              startIcon={
                <FAIcon
                  icon="times"
                  collection="fal"
                />
              }
              variant="contained"
              color="primary"
              onClick={() => handleCancelOrder(el._id)}
              style={{
                display: !!el.pendingOrder ? '' : 'none',
                marginRight: 14,
                background: theme.palette.error.main,
                color: 'white'
              }}
            >
              {t('cancelOrderReceive')}
            </Button>
            <Button
              startIcon={
                <FAIcon
                  icon="box-check"
                  collection="fal"
                />
              }
              variant="contained"
              color="primary"
              onClick={() => handleReceiveOrder(el._id)}
              style={{
                display: !!el.pendingOrder ? '' : 'none',
                background: theme.palette.success.dark,
                color: 'white'
              }}
            >
              {t('restockOrder')}
            </Button>
          </Box>

          {openDialogAdjustementStock && (
            <AjustementDialog
              defaultStock={el.stock}
              productId={el._id}
              onClose={() => setOpenDialogAdjustementStock((prev) => !prev)}
            />
          )}
          {openDialogOrder && (
            <OrderDialog
              product={el}
              onClose={() => setOpenDialogOrder((prev) => !prev)}
            />
          )}
        </div>
      ))}
    </>
  );
}

function AjustementDialog({ defaultStock, onClose, productId }) {
  const [stock, setStock] = useState(defaultStock);
  const { t } = useTranslation();
  const { dispatch } = useAsyncDispatch();

  const notify = useNotifications();

  const handleAdjustementStock = (stock) => {
    const dispatchCallbacks = {
      onSuccess: () => notify.success(t('adjustementStockSuccess')),
      onError: () => notify.error(t('changesNotSavedError'))
    };

    dispatch(adjustementStock, { stock }, dispatchCallbacks, { id: productId });

    onClose();
  };

  return (
    <Dialog
      disableEnforceFocus
      open
      onClose={onClose}
      maxWidth={'xs'}
      fullWidth
    >
      <DialogContent>
        <TextField
          style={{ marginTop: 16 }}
          id="standard-number"
          label="Nouveau stock"
          type="number"
          required
          InputLabelProps={{
            shrink: true
          }}
          fullWidth
          value={stock}
          onChange={(e) => setStock(e.target.value)}
          inputProps={{ min: 0 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="secondary"
        >
          {t('close')}
        </Button>
        <Button
          disabled={defaultStock === stock}
          color="primary"
          onClick={() => handleAdjustementStock(parseInt(stock))}
        >
          {t('validateAdjustementStock')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function OrderDialog({ onClose, product }) {
  const { dispatch } = useAsyncDispatch();
  const { t } = useTranslation();
  const notify = useNotifications();

  const handleOrderSubmit = (order) => {
    const dispatchCallbacks = {
      onSuccess: () => notify.success(t('orderRequestSuccess')),
      onError: () => notify.error(t('errorOccured'))
    };

    dispatch(submitNewOrder, order, dispatchCallbacks, { id: product._id });

    onClose();
  };

  const quantity = product.targetStock - product.stock;

  const stockIsNegative = quantity < 0 ? 0 : quantity;

  return (
    <Dialog
      disableEnforceFocus
      open
      onClose={onClose}
      maxWidth={'xs'}
      fullWidth
    >
      <Form
        initialValues={{
          _supplier: null,
          quantity: stockIsNegative,
          reference: '',
          email: false
        }}
        disableSubmitIfInvalid
        disableIf={(data) => !isObject(data._supplier)}
        onSubmit={(data) =>
          handleOrderSubmit({
            _supplier: data._supplier._id,
            quantity: parseInt(data.quantity),
            reference: data.reference,
            email: data.email
          })
        }
        schemaValidation={{
          _supplier: (val) => !!isObject(val) && !!val._id,
          quantity: (str) => str.length
        }}
      >
        <DialogContent>
          <Field
            path="quantity"
            isErrorProp="error"
          >
            <TextField
              style={{ marginTop: 16 }}
              id="quantity-order"
              label={t('orderQuantity')}
              type="number"
              required
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              inputProps={{ min: 0 }}
            />
          </Field>
          <Field path="reference">
            <TextField
              style={{ marginTop: 16 }}
              id="ref-order"
              label={t('orderReference')}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
              inputProps={{ min: 0 }}
            />
          </Field>
          <EntityX
            placeholder={t('supplierOrderPlaceholder')}
            required
            entity={Supplier}
            entityName="suppliers"
            path="_supplier"
            style={{ marginTop: 16 }}
            pickerUniq
            errorHelperText={t('errorHelperTextOrderStockSupplier')}
          />
          <FormControlLabel
            control={
              <Field
                path="email"
                type="checkbox"
              >
                <Checkbox />
              </Field>
            }
            label={t('sendEmailToSupplier')}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={onClose}
            color="secondary"
          >
            {t('close')}
          </Button>

          <Submit>
            <Button color="primary">{t('makeOrderStock')}</Button>
          </Submit>
        </DialogActions>
      </Form>
    </Dialog>
  );
}
