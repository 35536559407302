import { pick } from 'lodash-es';
import clone from 'utils/clone';

const MaintenanceFormValues = (_configuration, {}, user) => ({
  name: '',
  compute: 'regulatory',
  description: '',
  defaults: {
    config: {
      type: 'year'
    },
    autoAssign: true,
    planning: { enable: true, day: 90 },
    alert: { enable: true, day: 60 },
    job: null,
    intervener: null
  },
  _equipments: [],
  _locations: [],
  _managers: [pick(user, ['firstName', 'lastName', '_id'])],
  _clients: []
});
export default MaintenanceFormValues;

export const MaintenanceValidation = (_configuration) => ({});

export const formatForm = (data, isCreate, _attachments, _interface, { updates }) => {
  !isCreate && updates.push('apply_to_all_periods') && updates.push('fields');

  data = clone(data);
  data = isCreate ? data : pick(data, updates);

  if (_interface.isClient) data._clients = [_interface._company._id];

  return data;
};
