import { Box, Tooltip } from '@material-ui/core';
import { useMemo } from 'react';
import moment from 'moment';
import { contractStates } from 'lists/contractStates';
import FAIcon from 'components/ui/FAIcon';
import IconButton from 'components/IconButton';
import { useTranslation } from 'react-i18next';
import { dateToLocalFormat } from 'utils/dates';
import { differenceInCalendarDays } from 'date-fns';

export function TimelineRenderItem({ ticket, itemContext, item }) {
  const { t } = useTranslation();

  const selected = ticket.period.hasReserve;
  const offSet = 2;

  const { date, isLate, isAlert, alertPosition, planningPosition, datePosition } = useMemo(() => {
    const isAlert =
      ticket.period.current &&
      true &&
      moment().isSameOrAfter(ticket.period.alert_date) &&
      moment().isSameOrBefore(ticket.period.end_time);
    const isLate = ticket.period.current && moment().isAfter(ticket.period.end_time);

    const start_time = moment(ticket.period.start_time);

    const alertPosition =
      !isLate &&
      ticket.period.current &&
      ticket.period.alert_date &&
      ticket.state !== 'finished' &&
      (itemContext.width * start_time.diff(ticket.period.alert_date, 'days')) /
        start_time.diff(item.end_time, 'days') -
        offSet;

    const planningPosition =
      !isLate &&
      ticket.period.current &&
      ticket.period.planning_date &&
      (itemContext.width * start_time.diff(ticket.period.planning_date, 'days')) /
        start_time.diff(item.end_time, 'days') -
        offSet;

    const date = ticket.contract.intervention_date || ticket.contract.visit_date;
    const datePosition =
      ticket.period.current &&
      date &&
      (itemContext.width * start_time.diff(date, 'days')) / start_time.diff(item.end_time, 'days') -
        offSet;

    return {
      date,
      isAlert,
      isLate,
      start_time,
      alertPosition,
      planningPosition,
      datePosition
    };
  }, [
    ticket.contract.visit_date,
    ticket.contract.intervention_date,
    ticket.period.current,
    itemContext.width,
    ticket.period.start_time,
    ticket.period.end_time
  ]);

  const state = contractStates[ticket.contract.state];
  // * -------- End copy code from PeriodPreview.js --------- * //
  return (
    <Box style={{ height: itemContext.dimensions.height, width: '100%' }}>
      <span
        className="overflow-hidden text-ellipsis w-full h-full text-sm font-medium flex items-center"
        style={{
          color: !ticket.period.current || selected ? 'white' : state.color,
          fontSize: 12,
          fontWeight: 500
        }}
      >
        {/* EN RETARD */}
        {!!isAlert && (
          <Box
            style={{
              marginLeft: 6,
              color: selected ? 'white' : '#d35400',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <FAIcon
              className="relative right-1 mr-0.5"
              style={{ fontSize: 14 }}
              collection="fas"
              icon="exclamation-triangle"
            />
          </Box>
        )}

        {/* A SURVEILLER */}
        {!!isLate && (
          <Box
            style={{
              marginLeft: 6,
              color: selected ? 'white' : '#c0392b',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <FAIcon
              className="relative right-1 mr-0.5"
              collection="fas"
              icon="siren-on"
            />
          </Box>
        )}

        {/* BODY */}
        <Box className="flex flex-col">
          {!ticket.period.current ? (
            <Box className="flex flex-col justify-center leading-4 py-2">
              <span>
                #{ticket.period.index} {t(state.label)}
                {(!ticket.period.current || isLate) && <Gap period={ticket.period} />}
              </span>
              <Box className="flex items-center">
                <FAIcon
                  collection="fas"
                  icon="calendar"
                  className="text-[10px] mr-1"
                />
                {t('madeOn') + ' ' + dateToLocalFormat(date, 'P')}
              </Box>
            </Box>
          ) : (
            <Box className="">
              <span>
                #{ticket.period.index} {t(state.label)}
                {(!ticket.period.current || isLate) && <Gap period={ticket.period} />}
              </span>
            </Box>
          )}
        </Box>

        {/* DISPLAY ICON IF HAS REPORT */}
        {ticket.period.hasIR && ticket.period.current && (
          <IconButton size={'small'}>
            <FAIcon
              style={{ color: selected ? 'white' : 'inherit' }}
              collection="fas"
              icon="file-chart-pie"
            />
          </IconButton>
        )}
      </span>

      {/* Planning Date Position */}
      {!!planningPosition && (
        <Tooltip
          placement="left"
          title={
            t('maintenanceToPlanFrom') + ' ' + dateToLocalFormat(ticket.period.planning_date, 'P')
          }
        >
          <span
            style={{ left: planningPosition }}
            className={`
        w-[1px]
        top-0
        h-full
        ${selected ? 'hidden' : 'block'}
        z-[5]
        border-[1px]
        border-dashed
        ${selected ? 'border-white' : isLate ? 'border-[#c0392b]' : isAlert ? 'border-[#d35400]' : 'border-[#363640]'}
        absolute`}
          ></span>
        </Tooltip>
      )}

      {/* Alert Date Position */}
      {!!alertPosition && (
        <Tooltip
          placement="left"
          title={t('reminderOn') + ' ' + dateToLocalFormat(ticket.period.alert_date, 'P')}
        >
          <span
            style={{ left: alertPosition }}
            className={`
        w-[1px]
        top-0
        h-full
        ${selected ? 'hidden' : 'block'}
        z-[5]
        border-[1px]
        ${selected ? 'border-white' : isLate ? 'border-[#c0392b]' : isAlert ? 'border-[#d35400]' : 'border-[#363640]'}
        absolute`}
          >
            <FAIcon
              collection="fal"
              icon="bell-on"
              className={`
          ${selected ? 'text-white' : isLate ? 'text-[#c0392b]' : isAlert ? 'text-[#d35400]' : 'text-[#363640]'}
          relative
          translate-x-[-13px]
          translate-y-[9px]
        bg-white
          rounded-[8px]`}
            />
          </span>
        </Tooltip>
      )}

      {/* Date Position */}
      {!!datePosition && (
        <Tooltip
          placement="left"
          title={
            (ticket.contract.intervention_date ? t('realizationDate') : t('scheduledPassageDate')) +
            ' ' +
            dateToLocalFormat(date, 'P')
          }
        >
          <span
            style={{ left: datePosition }}
            className={`
        w-[1px]
        top-0
        h-full
        ${selected ? 'hidden' : 'block'}
        z-[5]
        border-[1px]
        ${selected ? 'border-white' : isLate ? 'border-[#c0392b]' : isAlert ? 'border-[#d35400]' : 'border-[#363640]'}
        absolute`}
          >
            <FAIcon
              collection="fal"
              icon="calendar-day"
              className={`
          ${selected ? 'text-white' : isLate ? 'text-[#c0392b]' : isAlert ? 'text-[#d35400]' : 'text-[#363640]'}
          relative
          translate-x-[-13px]
          translate-y-[9px]
        bg-white
          rounded-[8px]`}
            />
          </span>
        </Tooltip>
      )}
    </Box>
  );
}

function Gap({ period }) {
  const gap = useMemo(() => {
    const period_gap =
      period.ecart || differenceInCalendarDays(new Date(period.end_time), new Date());
    return period_gap;
  }, [period.ecart, period.end_time]);

  return (
    <>
      {gap >= 0 ? (
        <span className="ml-2 text-[#81c784]">+{Math.abs(gap)}j</span>
      ) : (
        gap < 0 && <span className="ml-2 text-[#e57373]">-{Math.abs(gap)}j</span>
      )}
    </>
  );
}
