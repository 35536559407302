import { createSlice } from '@reduxjs/toolkit';
import { generateEntityBoilerplate } from 'utils/generateEntitySlice';
import generateAsyncThunk from 'utils/generateAsyncThunk';

// We only need this to have the report template picker
// Otherwise everything is in the fieldSectionsSlice
export const createElement = generateAsyncThunk({ type: 'FILE', endpoint: 'bullshitAPI' });
export const deleteElement = generateAsyncThunk({ type: 'DELETE', endpoint: 'bullshitAPI/${id}' });
export const updateElement = generateAsyncThunk({
  type: 'PUT',
  endpoint: 'bullshitAPI/${id}',
  prefix: 'reports'
});
export const getElement = generateAsyncThunk({
  type: 'GET',
  endpoint: 'bullshitAPI/interventionReport/${id}',
  prefix: 'reports'
});
export const restoreElement = generateAsyncThunk({
  type: 'POST',
  endpoint: 'bullshitAPI/recycle/${id}',
  prefix: 'reports'
});

//! USED ENDPOINTS
export const getElements = generateAsyncThunk({
  type: 'POST',
  endpoint: 'ir/templates',
  prefix: 'reports'
});
// TODO does'nt work (empty page)
// export const exportElement = generateAsyncThunk({ type: "GET-BLOB", endpoint: "miscs/interventionReport/${id}/preview", prefix: 'reports' })
// export const getElements = generateAsyncThunk({ type: "POST", endpoint: "ir/list", prefix: 'reports' })

const entityBoilerplate = generateEntityBoilerplate({
  createElement,
  updateElement,
  getElement,
  getElements,
  deleteElement,
  restoreElement
});

const { initialState, reducers, extraReducers } = entityBoilerplate;

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers,
  extraReducers
});

export const { flushElements, flushElement } = reportsSlice.actions;
export default reportsSlice.reducer;
