import useKeys from '@flowsn4ke/usekeys';
import { CheckIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import Tooltip from 'components/ToolTip';
import FAIcon from 'components/ui/FAIcon';
import useFileViewer from 'hooks/useFileViewer';
import useNotifications from 'hooks/useNotifications';
import { apiBaseURL } from 'index';
import { contractStates } from 'lists/contractStates';
import { useTranslation } from 'react-i18next';
import { dateToLocalFormat } from 'utils/dates';
import { trim } from 'utils/trim';

export default function TableRow({
  setOpenTicketId,
  setShowDialog,
  commission,
  commissionsIdx,
  data,
  setData,
  setCommentsTabId
}) {
  const k = useKeys();
  const notify = useNotifications();
  const { t } = useTranslation();
  const { openFiles } = useFileViewer();

  const colorText = contractStates[commission.ticketState].color;
  const label = contractStates[commission.ticketState].label;
  const hasReserve = commission.hasReserve;

  const interventionDates = commission.interventionDate;
  const interventionReport = commission.interventionReport ? commission.interventionReport : null;

  const commentsTabId = commission.comments_tab_id;

  return (
    <tr className={commissionsIdx % 2 === 0 ? undefined : 'bg-gray-50'}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <span
          onClick={() => setOpenTicketId(commission.ticketId)}
          className="hover:cursor-pointer hover:font-bold hover:underline"
        >
          #{commission.ticketNumber}
        </span>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
        {trim(commission.generatorName, 33)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {commission.locationName}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 hidden sm:table-cell">
        <span
          className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium text-white"
          style={{ border: `1px solid ${colorText}`, color: colorText }}
        >
          {t(label)}
        </span>
      </td>
      <td
        className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 ${hasReserve ? '' : 'text-center font-bold'}`}
      >
        {hasReserve ? (
          <button
            type="button"
            onClick={async () => {
              return axios
                .get(`${apiBaseURL}/tickets/${commission.ticketId}/interventionReport/reserve`)
                .then((res) => {
                  const liftTheReserve = data.find((c) => c.ticketId === commission.ticketId);
                  liftTheReserve.hasReserve = false;
                  setData([...data]);
                  notify.success(t('liftTheReserveSuccessMessage'));
                })
                .catch((err) => {
                  notify.error();
                });
            }}
            className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            <CheckIcon
              className="-ml-0.5 mr-2 h-4 w-4"
              aria-hidden="true"
            />
            {t('liftTheReserve')}
          </button>
        ) : (
          '—'
        )}
      </td>
      {interventionDates.length > 0 ? (
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center font-bold hidden sm:table-cell">
          {interventionDates
            .sort((a, b) => new Date(a) > new Date(b))
            .map((date, dateIdx) => {
              if (dateIdx === 0) {
                return <p key={k(dateIdx)}>{dateToLocalFormat(date, 'P')}</p>;
              }
              return null;
            })}
        </td>
      ) : (
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">—</td>
      )}
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <div className="flex">
          <Tooltip message={interventionReport.length === 0 ? t('noReport') : t('seeReports')}>
            <button
              disabled={interventionReport.length === 0}
              type="button"
              onClick={() => openFiles(interventionReport)}
              className="inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-medium"
            >
              <FAIcon icon="file-chart-pie" />
            </button>
          </Tooltip>
          <Tooltip message={t('seeComments')}>
            <button
              type="button"
              onClick={() => [setShowDialog(true), setCommentsTabId(commentsTabId)]}
              className="inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-medium"
            >
              <FAIcon icon="message-lines" />
            </button>
          </Tooltip>
        </div>
      </td>
    </tr>
  );
}
