import { Grid, TextField } from '@material-ui/core';
import Role from 'entities/Role/Role';
import { useConfiguration } from 'hooks/useConfiguration';
import { Field } from 'frmx';
import PhoneField from 'components/inputs/PhoneField';
import EntityX from 'components/inputs/EntityX';
import { useTranslation } from 'react-i18next';
import Location from 'entities/Location/Location';
import { LangSelect } from 'entities/User/UserForm';

export default function ManagerForm() {
  const configuration = useConfiguration();
  const { t } = useTranslation();

  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field path="firstName">
          <TextField
            autoFocus
            variant="outlined"
            label={t('firstName')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <Field path="lastName">
          <TextField
            variant="outlined"
            label={t('lastName')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
      >
        <Field
          isErrorProp="error"
          path="email"
        >
          <TextField
            variant="outlined"
            label={t('email')}
            fullWidth
          />
        </Field>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <PhoneField
          path="phone"
          label={t('cellphoneNumber')}
          variant="outlined"
          placeholder={t('cellphoneNumber')}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
      >
        <PhoneField
          path="fix"
          label={t('landlineNumber')}
          variant="outlined"
          placeholder={t('landlineNumber')}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <EntityX
          path="_role"
          entity={Role}
          entityProps={{
            defaultFilters: {
              types: [
                configuration.isMaster
                  ? 'master'
                  : configuration.isContractor
                    ? 'contractor'
                    : 'client'
              ]
            }
          }}
          placeholder={t('role')}
          pickerUniq
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <EntityX
          path="_locations"
          entity={Location}
          placeholder={t('locations')}
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <LangSelect
          path="lang"
          label={t('lang')}
        />
      </Grid>
    </>
  );
}
